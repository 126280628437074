import React, { useEffect, useState, useRef } from "react";
import { IoSearch } from "react-icons/io5";
import { useNavigate } from 'react-router-dom';
import { MdSearchOff } from "react-icons/md";
import axios from "axios";
import { windowScroll } from '../../components/utils/windowScroll';


const Search = () => {
    const [filteredCities, setFilteredCities] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");
    const navigate = useNavigate();
    const [search, setSearch] = useState([]);
    console.log("search", search);

    console.log("searchTerm", searchTerm);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_URL}user/getcities`);
                setSearch(response?.data?.data);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchData();
    }, []);

    useEffect(() => {
        const filteredServiceResult = Array.isArray(search) && search.length > 0
            ? search.filter((serviceItem) => {
                console.log("serviceItem", serviceItem);
                const searchTermLower = searchTerm.toLowerCase();
                console.log("searchTermLower", searchTermLower);
                return (
                    serviceItem?.cityname.toLowerCase().includes(searchTermLower)
                );
            })
            : [];
        setFilteredCities(filteredServiceResult);
    }, [searchTerm, search]);

    console.log("filteredCities", filteredCities);

    const searchDropdownRef = useRef(null);
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (searchDropdownRef.current && !searchDropdownRef.current.contains(event.target)) {
                setSearchTerm('');
            }
        };

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [searchDropdownRef]);

    const handleSuggestionClick = (cityName) => {
        navigate(`/cities/${cityName}`);
        setSearchTerm("");
        windowScroll()
    };

    const handleSearchEnter = (e) => {
        if (e.key === 'Enter' && searchTerm.trim() !== "" && filteredCities.length > 0) {
            const cityName = filteredCities[0]?.cityname?.replace(/\s+/g, "-").toLowerCase();
            navigate(`/cities/${cityName}`);
            setSearchTerm("");
            windowScroll()


        }
    };

    const handleSearchIconClick = () => {
        if (searchTerm.trim() !== "" && filteredCities.length > 0) {
            const cityName = filteredCities[0]?.cityname?.replace(/\s+/g, "-").toLowerCase();
            navigate(`/cities/${cityName}`);
            setSearchTerm("");
            windowScroll()

        }
    };

    return (
        <>
            <div className="pt-20 pb-[20px]">
                <div className="container !max-w-[900px] mx-auto">
                    <form>
                        <div className="flex w-full relative">
                            <input
                                type="text"
                                id="search-dropdown"
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)}
                                onKeyDown={handleSearchEnter}
                                className="border border-[#b8b8b8] focus:outline-none bg-[#fff] text-[#000] w-full px-[20px] h-[50px]"
                                placeholder="Search for your city"
                            />
                            {searchTerm && (
                                <div className=' absolute rounded shadow-[0_5px_10px_5px_rgba(0,0,0,0.1)] bg-white z-20 mt-[5px] w-full top-full' ref={searchDropdownRef}>
                                    {filteredCities.length === 0 ? (
                                        <ul>
                                            <li>
                                                <div className='px-[20px] h-[50px] border-b flex items-center'>
                                                    <MdSearchOff className='h-5 w-6 cursor-pointer' />
                                                    Not Found
                                                </div>
                                            </li>
                                        </ul>
                                    ) : (
                                        <ul>
                                            {filteredCities.map((serviceItem) => (
                                                <li key={serviceItem?._id} className='w-full text-center '>
                                                    <div
                                                        className='px-[20px] h-[50px] border-b flex items-center'
                                                        onClick={() => handleSuggestionClick(serviceItem?.handle?.replace(/\s+/g, "-").toLowerCase())}
                                                        style={{ cursor: 'pointer' }}
                                                    >
                                                        {serviceItem?.cityname}
                                                    </div>
                                                </li>
                                            ))}
                                        </ul>
                                    )}
                                </div>
                            )}
                            <div
                                className="absolute md:h-[50px] max-md:h-[40px] md:min-w-[50px] max-md:min-w-[40px] py-[8px] right-0 top-0 flex items-center justify-center text-white bg-[#f38432] cursor-pointer"
                                onClick={handleSearchIconClick}
                            >
                                <IoSearch className='md:text-[18px] max-md:text-[16px]' />
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </>
    );
}

export default Search;
