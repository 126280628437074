import React from "react";
import CountUp from 'react-countup';

const Counting = () => {
    return (
        <>
            <section className="lg:pt-[25px] pt-0">
                <div className="container m-auto">
                    <div className="grid grid-cols-2 md:grid-cols-4 xl:gap-[25px] lg:gap-[20px] sm:gap-[15px] gap-[12px] justify-between items-center">
                        <div className='overflow-hidden md:py-[50px] sm:py-[40px] py-[30px] px-[15px] bg-[#fff4e6] rounded-[10px] text-center pt-35px] relative after:content-[""]  after:absolute after:right-[-30px] after:h-3/5 after:rotate-[19deg] after:w-[105px] after:transition-[0.1s] after:duration-[all] after:ease-[ease-in] after:rounded-[30px] after:bottom-[-60px] after:opacity-[0.1] after:!bg-[#f38432]'>
                            <span className="absolute animate-bounce duration-700 transition ease-in-out left-[35px] top-[35px] opacity-[0.1] h-[35px] w-[35px] rounded-[50%] bg-[#f38432]"></span>
                            <span className="xl:text-[48px] lg:text-[44px] sm:text-[36px] text-[25px] font-black mb-[10px] inline-block after:content-['+'] after:pl-[4px] after:xl:text-[48px] after:lg:text-[44px] after:sm:text-[36px] after:text-[25px] after:font-black after:relative">
                                <CountUp
                                    start={0}
                                    end={100}
                                    duration={2}
                                />
                            </span>

                            <p className="mb-0 xl:text-[18px] text-[16px]">Resellers</p>
                        </div>

                        <div className='bg-[#e7fde9] overflow-hidden md:py-[50px] sm:py-[40px] py-[30px] px-[15px] rounded-[10px] text-center pt-35px] relative after:content-[""]  after:absolute after:right-[-30px] after:h-3/5 after:rotate-[19deg] after:w-[105px] after:transition-[0.1s] after:duration-[all] after:ease-[ease-in] after:rounded-[30px] after:bottom-[-60px] after:opacity-[0.1] after:!bg-[#f38432]'>
                            <span className="absolute animate-bounce duration-700 transition ease-in-out left-[35px] top-[35px] opacity-[0.1] h-[35px] w-[35px] rounded-[50%] bg-[#f38432]"></span>
                            <span className="xl:text-[48px] lg:text-[44px] sm:text-[36px] text-[25px] font-black mb-[10px] inline-block after:content-['+'] after:pl-[4px] after:xl:text-[48px] after:lg:text-[44px] after:sm:text-[36px] after:text-[25px] after:font-black after:relative">
                                <CountUp start={0} end={150} duration={2} />
                            </span>

                            <p className="mb-0 xl:text-[18px] text-[16px]">Cities</p>
                        </div>

                        <div className='bg-[#f2f2fd] overflow-hidden md:py-[50px] sm:py-[40px] py-[30px] px-[15px] rounded-[10px] text-center pt-35px] relative after:content-[""]  after:absolute after:right-[-30px] after:h-3/5 after:rotate-[19deg] after:w-[105px] after:transition-[0.1s] after:duration-[all] after:ease-[ease-in] after:rounded-[30px] after:bottom-[-60px] after:opacity-[0.1] after:!bg-[#f38432]'>
                            <span className="absolute animate-bounce duration-700 transition ease-in-out left-[35px] top-[35px] opacity-[0.1] h-[35px] w-[35px] rounded-[50%] bg-[#f38432]"></span>
                            <span className="xl:text-[48px] lg:text-[44px] sm:text-[36px] text-[25px] font-black mb-[10px] inline-block after:content-['+'] after:pl-[4px] after:xl:text-[48px] after:lg:text-[44px] after:sm:text-[36px] after:text-[25px] after:font-black after:relative">
                                <CountUp start={0} end={10000} duration={2} />
                            </span>

                            <p className="mb-0 xl:text-[18px] text-[16px]">Villages Connected</p>
                        </div>

                        <div className='bg-[#fdf1f5] overflow-hidden md:py-[50px] sm:py-[40px] py-[30px] px-[15px] rounded-[10px] text-center pt-35px] relative after:content-[""]  after:absolute after:right-[-30px] after:h-3/5 after:rotate-[19deg] after:w-[105px] after:transition-[0.1s] after:duration-[all] after:ease-[ease-in] after:rounded-[30px] after:bottom-[-60px] after:opacity-[0.1] after:!bg-[#f38432]'>
                            <span className="absolute animate-bounce duration-700 transition ease-in-out left-[35px] top-[35px] opacity-[0.1] h-[35px] w-[35px] rounded-[50%] bg-[#f38432]"></span>
                            <span className="xl:text-[48px] lg:text-[44px] sm:text-[36px] text-[25px] font-black mb-[10px] inline-block after:content-['+'] after:pl-[4px] after:xl:text-[48px] after:lg:text-[44px] after:sm:text-[36px] after:text-[25px] after:font-black after:relative">
                                <CountUp start={0} end={22000} duration={2} />
                            </span>

                            <p className="mb-0 xl:text-[18px] text-[16px]">Homes Connected</p>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
export default Counting