import React, { useState, useRef, useEffect } from 'react';
import Layout from '../components/shared/layout';
import InnerPageBanner from '../components/shared/innerPageBanner';
import ImageIcons from '../components/imageComponent/ImageIcons';
import pricingData from '../components/utils/data/pricingData.json';
import { useLocation, useParams, useNavigate } from "react-router-dom";
import { IoCheckmarkDoneSharp } from 'react-icons/io5';
import { FaMinus, FaPlus, FaRocket } from 'react-icons/fa';
import Button from '../components/shared/button';
import SEO from '../components/shared/seo';

const Pricing = () => {
  const { handle } = useParams();
  const navigate = useNavigate()
  const [pricingDetails, setPricingDetails] = useState(null);
  const [activeIndex, setActiveIndex] = useState(null);
  const pathname = useLocation().pathname;
  console.log(pathname)

  useEffect(() => {
    const selectedPricing = pricingData.find(pricing => pricing.handle === handle)
    setPricingDetails(selectedPricing)
  }, [handle])

  if (!pricingDetails) {
    navigate('*');
  }

  const handleToggle = (index) => {
    setActiveIndex((prevIndex) => (prevIndex === index ? null : index));
  };


  return (
    <>
<SEO title={pricingDetails?.metaTitle} description={pricingDetails?.description} keywords={pricingDetails?.keywords}/>
    <Layout>
      <InnerPageBanner title={pricingDetails?.title} headingH2='headingH2' innerPageBanner={pricingDetails?.image} />
      {(pricingDetails?.customPlans && pricingDetails?.customPlansDescription) &&
        <section className='lg:my-[25px] my-[15px] relative pb-[0]'>
          <div className='absolute w-[220px] left-[20px] bottom-[20px] animate-[animate-bounceTop_5s_infinite_ease-in-out_alternate] max-lg:opacity-70'>
            <img className='object-cover rotate-180' src={ImageIcons.aboutShape} alt='Shape' />
          </div>
          <div className='container'>
            <div className='flex lg:flex-nowrap flex-wrap lg:gap-[50px] gap-[40px] items-center'>
              <div className='lg:w-[50%] max-lg:order-2 w-full lg:py-[40px] py-0 relative ' >
                <div className='relative z-10'>
                  <h1>{pricingDetails?.customPlans}</h1>

                  <p className='mb-0'>{pricingDetails?.customPlansDescription}</p>
                </div>
              </div>
              <div className='md:min-h-[500px] max-lg:order-1 sm:min-h-[400px] md:mr-[25px] mr-[15px] min-h-[300px] relative lg:w-[50%] w-full after:content-[""] after:absolute after:rounded-[20px] md:after:top-[-25px] after:top-[-15px] md:after:right-[-25px] after:right-[-15px] after:w-[calc(100%_-_50%)] after:z-[-1] after: after:bg-[#f38432] after:h-full'>
                <img className='object-cover h-full w-full absolute rounded-[20px]' src={ImageIcons.leaseLine} alt='Lease Line' />
              </div>
            </div>
          </div>
        </section>
      }

      {(pricingDetails?.WhatCustomPlans && pricingDetails?.WhatCustomPlansDescription) &&
        <section className='relative overflow-hidden lg:mb-[25px]'>
          <div className='absolute w-[250px] right-0 bottom-[20px] animate-[animate-bounceTop_5s_infinite_ease-in-out_alternate] max-lg:opacity-30 opacity-40'>
            <img className='object-cover' src={ImageIcons.inneraboutShape} alt='Shape' />
          </div>
          <div className='container '>
            <div className='flex lg:flex-nowrap flex-wrap items-center'>
              <div className='md:min-h-[500px] sm:min-h-[400px] md:ml-[25px] ml-[15px] min-h-[330px] relative lg:w-[50%] w-full content-[""] after:absolute md:after:top-[-25px] after:top-[-15px] md:after:left-[-25px] after:left-[-15px] after:w-[calc(100%_-_80px)] after:z-[-1] after:animate-[auto-fill_5s_infinite_ease-in-out_alternate] after:rounded-[20px] after:bg-[#f38432] md:after:h-[calc(100%_+_50px)] after:h-[calc(100%_+_30px)]'>
                <img className='object-cover h-full w-full absolute rounded-[20px]' src={ImageIcons.myinternetBanner} alt='MyInternet' />
              </div>

              <div className='lg:w-[50%] w-full xl:pl-[50px] lg:pl-[40px] relative max-lg:pt-[50px]' >
                <div className='relative z-10 lg:max-w-[700px] max-w-full'>
                  <h2>{pricingDetails?.WhatCustomPlans}</h2>

                  <p className='mb-0'>{pricingDetails?.WhatCustomPlansDescription}</p>
                </div>
              </div>
            </div>
          </div>
        </section>
      }

      {(pricingDetails?.keyFeature && pricingDetails?.keyFeatureList) &&
        <section className='bg-[#fff3ea]'>
          <div className='container '>
            <h2 className='text-center mb-[40px]'>{pricingDetails?.keyFeature}</h2>

            <ul className='grid sm:grid-cols-2 grid-cols-1 sm:gap-[40px] gap-[30px]'>
              {pricingDetails?.keyFeatureList?.map((itemList, index) => (
                <li className='relative pl-[55px] group'>
                  <span className='text-[22px] flex items-center justify-center text-white bg-[#f48533] group-hover:bg-[#fd5901] duration-500 absolute left-0 top-0 w-[40px] h-[40px] rounded-full '>{index + 1}</span>
                  {itemList?.item}</li>
              ))}
            </ul>
          </div>
        </section>
      }

      {(pricingDetails?.BenefitsCustomPlans && pricingDetails?.BenefitsCustomPlansList) &&
        <section>
          <div className='container '>
            <div className='mb-[30px]'>
              <h2>{pricingDetails?.BenefitsCustomPlans}</h2>
              {(pricingDetails?.BenefitsCustomPlansDescription) &&
                <p>{pricingDetails?.BenefitsCustomPlansDescription}</p>
              }
            </div>

            <ul>
              {pricingDetails?.BenefitsCustomPlansList?.map((itemList) => (
                <li className='relative pl-[25px] mb-[15px] last:mb-0'><IoCheckmarkDoneSharp className='text-[17px] inline-block text-[#f48533] absolute left-0 top-[5px]' />{itemList?.item}</li>
              ))}
            </ul>
          </div>
        </section>
      }

      {(pricingDetails?.customPackagesHeading && pricingDetails?.customPackages) &&
        <section className={` ${pathname === '/pricing/custom-plan' && "p-0"}`}>
          <div className='container '>
            <div className='mb-[30px]'>
              <h2 className='mb-[15px]'>{pricingDetails?.customPackagesHeading}</h2>
              {(pricingDetails?.customPackagesDescription) &&
                <p>{pricingDetails?.customPackagesDescription}</p>
              }
            </div>

            <div className='grid lg:grid-cols-3 md:grid-cols-2 xl:gap-[30px] gap-[20px]'>
              {pricingDetails?.customPackages?.map((item, index) => (
                <div className='relative border border-[#f48432] p-[30px] rounded-[4px] hover:bg-[#f48432] hover:text-white duration-500 overflow-hidden group'>
                  <span className='absolute right-[20px] -top-[40px] group-hover:top-[20px] duration-1000 text-white text-[40px] leading-[40px] opacity-50 font-bold'>0{index + 1}</span>
                  <h3 className='mb-[15px]'>{item?.title}</h3>
                  <p className='mb-0'>{item?.description}</p>
                </div>
              ))}
            </div>
          </div>
        </section>
      }

      {(pricingDetails?.SubscriptionPlansHeading && pricingDetails?.SubscriptionPlans) &&
        <section className="relative bg-no-repeat bg-fixed after:absolute after:content-[''] after:w-full after:h-full after:right-0 after:top-0 after:bg-[#fff5ee] after:opacity-90 bg-cover" style={{ backgroundImage: `url(${ImageIcons.expansion})` }}>
          <div className='container relative z-10'>
            <h2 className='text-center mb-[40px]'>{pricingDetails?.SubscriptionPlansHeading}</h2>

            <div className='grid md:grid-cols-3 xl:gap-[40px] lg:gap-[30px] md:gap-[20px] gap-[40px]'>
              {pricingDetails?.SubscriptionPlans?.map((item) => (
                <div className='bg-[#fff] text-center py-[50px] lg:px-[40px] px-[25px] rounded-[10px] mt-[40px] group border-[#b7b7b7] hover:border-[#141416] border-[1px] duration-500'>
                  <span className='w-[80px] h-[80px] -mt-[90px] mb-[20px] shadow-[0px_0px_0px_4px_rgba(244,133,51,0.25)] bg-white rounded-full flex justify-center items-center mx-auto text-[28px] text-[#f48533] duration-700 group-hover:rotate-[360deg] group-hover:scale-[1.1] group-hover:text-white group-hover:bg-[#f48533]'><FaRocket /></span>
                  <h3 className='pt-2'>{item.title}</h3>
                  <p className='mb-0'>{item?.description}</p>
                </div>
              ))
              }
            </div>
          </div>
        </section>
      }

      {(pricingDetails?.servicesHeading && pricingDetails?.services) &&
        <section className='pb-0'>
          <div className='container relative z-10'>
            <div className='mb-[30px]'>
              <h2 className='mb-[15px]'>{pricingDetails?.servicesHeading}</h2>
              {(pricingDetails?.servicesDescription) &&
                <p>{pricingDetails?.servicesDescription}</p>
              }
            </div>

            <div>
              {pricingDetails?.services?.map((item) => (
                <div className='py-[10px] mb-[30px] last:mb-0 pl-[20px] border-l-[4px] first:border-[#da4b48] [&:nth-child(2)]:border-[#21c87a] [&:nth-child(3)]:border-[#796eff] first:hover:text-[#da4b48] [&:nth-child(2)]:hover:text-[#21c87a] [&:nth-child(3)]:hover:text-[#796eff] duration-500'>
                  <h3 className='mb-[10px]'>{item.title}</h3>
                  <p className='mb-0'>{item?.description}</p>
                </div>
              ))
              }
            </div>
          </div>
        </section>
      }

      {(pricingDetails?.businessPlans && pricingDetails?.businessPlansList) &&
        <section className='pb-0'>
          <div className='container'>
            <div className='mb-[30px]'>
              <h2>{pricingDetails?.businessPlans}</h2>
            </div>

            <ul>
              {pricingDetails?.businessPlansList?.map((itemList) => (
                <li className='relative pl-[25px] mb-[15px] last:mb-0'><IoCheckmarkDoneSharp className='text-[17px] inline-block text-[#f48533] absolute left-0 top-[5px]' />{itemList?.item}</li>
              ))}
            </ul>
          </div>
        </section>
      }

      <section className='relative pb-0'>
        <div className='container'>
          <div className='bg-[#633b25] text-white md:p-[50px] p-[30px] flex md:flex-nowrap flex-wrap justify-between items-center gap-[25px] rounded-[10px] bg-cover bg-no-repeat' style={{ backgroundImage: `url(${ImageIcons.faqBg})` }}>
            <div className='max-lg:w-[400px] max-md:w-full'>
              <h2 className='font-medium mb-[10px]'>Get in touch</h2>
              <p className='lg:text-[25px] md:text-[20px] text-[18px] mb-0'>Head over to our contact page to connect with us today!</p>
            </div>

            <Button hrefLink='/contact-us' title="Contact Us" />
          </div>
        </div>
      </section>

      <section>
        <div className="container">
          <div className="mx-auto bg-white rounded-lg">
            <h2 className='mb-[40px]'>{pricingDetails?.pricingFaqHeading}</h2>
            {pricingDetails?.pricingFaq?.map((faq, index) => (
              <AccordionItem
                key={index}
                active={activeIndex === index}
                handleToggle={() => handleToggle(index)}
                faq={faq}
              />
            ))}
          </div>
        </div>
      </section>

      {(pricingDetails?.overallDescription) &&
        <section className='pt-0'>
          <div className='container'>
            <p className='mb-0'><i>{pricingDetails?.overallDescription}</i></p>
          </div>
        </section>
      }

    </Layout>
    </>
  );
}

const AccordionItem = ({ handleToggle, active, faq }) => {
  const contentRef = useRef(null);
  const [height, setHeight] = useState('0px');

  useEffect(() => {
    if (contentRef.current) {
      setHeight(active ? `${contentRef.current.scrollHeight}px` : '0px');
    }
  }, [active]);

  return (
    <div className="mb-[20px] last:mb-0 shadow-[0px_3px_15px_0px_rgba(0,0,0,0.1)] overflow-hidden rounded-[10px]">
      <div
        className={`p-[20px] flex justify-between items-center cursor-pointer ${active ? 'bg-[#f48533] text-white' : ''}`}
        onClick={handleToggle}
      >
        <h5 className="mb-0 lg:text-[20px] text-[18px] ">{faq.question}</h5>
        <span>{active ? <FaMinus /> : <FaPlus />}</span>
      </div>
      <div
        ref={contentRef}
        className={`overflow-hidden transition-all duration-300`}
        style={{ maxHeight: height }}
      >
        <div className="p-[20px] ">
          <p className='mb-0'>{faq.answer}</p>

          {faq.answerList && <ul className='mt-[15px]'>
            {faq.answerList.map((item) => (
              <li className='mb-[8px] text-[16px] relative pl-[25px]'><IoCheckmarkDoneSharp className='text-[17px] inline-block text-[#f48533] absolute left-0 top-[5px]' />{item}</li>
            ))}
          </ul>
          }
        </div>
      </div>
    </div>
  );
};
export default Pricing;