import React from 'react'
import Layout from '../components/shared/layout'
import InnerPageBanner from '../components/shared/innerPageBanner'
import ImageIcons from '../components/imageComponent/ImageIcons'
import { IoCheckmarkDoneSharp } from 'react-icons/io5'

const PrivacyPolicy = () => {
  const storedUser = JSON.parse(localStorage.getItem('user'));
  const User = storedUser?._id;
  console.log("parsedUser", User?._id);

  return (
    <Layout>
      <InnerPageBanner title='Privacy Policy' innerPageBanner={ImageIcons.PrivacyPolicy} />
      <section>
        <div className='container'>
          {storedUser?._id}

          <p>While you are using our site, you are asked to enter user information like name, address, telephone, email address. This information is only used for the purpose it was collected for. By entering the user information, you are considered to agree to our policies and this information may be held by us or any third party which processes it on our behalf.</p>

          <h4>We are entitled to use your information for the following purposes</h4>
          <ul className='mb-[20px]'>
            <li className='mb-[6px] text-[16px] relative pl-[25px]'><IoCheckmarkDoneSharp className='text-[17px] inline-block text-[#f48533] absolute left-0 top-[5px]' />Requisition of subscriber information by any governmental or semi governmental authorities, courts of law.</li>
            <li className='mb-[6px] text-[16px] relative pl-[25px]'><IoCheckmarkDoneSharp className='text-[17px] inline-block text-[#f48533] absolute left-0 top-[5px]' />It is also used for market related research including statistical analysis of user behaviour which we may disclose to third parties in de-personalised form.</li>
            <li className='mb-[6px] text-[16px] relative pl-[25px]'><IoCheckmarkDoneSharp className='text-[17px] inline-block text-[#f48533] absolute left-0 top-[5px]' />It is also used for compliance with any requirements imposed on us by law.</li>
            <li className='mb-[6px] text-[16px] relative pl-[25px]'><IoCheckmarkDoneSharp className='text-[17px] inline-block text-[#f48533] absolute left-0 top-[5px]' />For the purpose of sending periodic communications about features, products and services, events and special offers. Such communication by our side may include advertising for third party companies or organisations.</li>
            <li className='mb-[6px] text-[16px] relative pl-[25px]'><IoCheckmarkDoneSharp className='text-[17px] inline-block text-[#f48533] absolute left-0 top-[5px]' />For subscriber convenience, you can withdraw consent by replying to the unsubscribe link in emails from us.</li>
          </ul>

          <p>It is important to note that we do not disclose your personal information to any third party in order to enable them to send any direct marketing without any prior permission of such subscriber to do so. Security is our top most priority, so all the security measures are being put in place in order to safeguard your confidentiality, integrity and availability of subscriber user information. Strict physical, electronics, and administrative safeguards are being maintained by us in order to provide protection to the personal information of the subscriber from any unauthorised or inappropriate access.</p>

          <p>The access to information about the subscriber is restricted to only those employees who need to know the information to respond to subscriber inquiry or request. In case any employee is found misusing our personal information, one is subjected to disciplinary action. We will comply with all relevant data protection legislation in relation to the period for which we retain any information the subscriber discloses to us.</p>

          <p>The privacy policy can be changed from time to time and the subscriber needs to check this out regularly. Use of the sites myinternetzone.com and myinternet.services of Digitax India Communications Private Limited will be deemed an acceptance of the privacy policy existing at that time.</p>

          <p>Any site that a subscriber may connect to from here is not covered by this privacy policy. If you submit your information on any of the similar or fake websites, which resembles ours, you alone will be responsible for any consequences of such usage.</p>


          <ul className='mb-[20px]'>
            <li className='mb-[6px] text-[16px] relative pl-[25px]'><IoCheckmarkDoneSharp className='text-[17px] inline-block text-[#f48533] absolute left-0 top-[5px]' />The subscriber does not have right to the accessories and shall return the same upon deactivation of the services.</li>
            <li className='mb-[6px] text-[16px] relative pl-[25px]'><IoCheckmarkDoneSharp className='text-[17px] inline-block text-[#f48533] absolute left-0 top-[5px]' />The subscriber is aware that the accessories are permitted to be used till such time he continues subscribing to the services from us.In the event he discontinues subscription of the services or his subscription gets terminated due to any reason, he shall be liable to return the accessories to the company without any resistance or objection.</li>
          </ul>

          <h4>Location usage on mobile app</h4>
          <p>When you download My Internet mobile App through your device, we may receive information about your location and your device, including a unique identifier number for Your device, device model, operating systems, versions, software, file names and mobile network information. We may use this information to provide you with location-based services including but not limited to updating subscriber’s location, tracking technician location to allocated tickets based on proximity to subscriber and update location of leads for deployment.</p>
          <p>When you use the mobile app through the telecommunication device, we collect your location data. If you permit the app to access your location through the permission system used by your mobile operating system. We may also collect the precise location of your device when the App is running in the foreground or background. We may also derive your approximate location from Your IP address.</p>


        </div>
      </section>
    </Layout>
  )
}

export default PrivacyPolicy