export const windowScroll = () => {
    window.scrollTo({
        top: 0,
        behavior: "smooth"
    });
}

export const windowScrolls = (topValue) => {
    if (typeof topValue === 'number') {
        window.scrollTo({
            top: topValue,
            behavior: "smooth"
        });
    } else {
        console.error('Invalid parameter: Please provide a number.');
    }
}

// Example usage:
// Scroll to 500 pixels from the top
windowScroll(1000);

