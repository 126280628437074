import React, { useState } from 'react';
import Layout from '../components/shared/layout';
import InnerPageBanner from '../components/shared/innerPageBanner';
import ImageIcons from '../components/imageComponent/ImageIcons';
import { FaHandshake } from "react-icons/fa6";
import { SiPicartodottv } from "react-icons/si";
import { FaExpandArrowsAlt } from "react-icons/fa";
import { FaHandHoldingHeart } from "react-icons/fa";
import { FaSackDollar } from "react-icons/fa6";
import Button from '../components/shared/button';
import { IoCheckmarkDoneSharp } from "react-icons/io5";

const Invest = () => {
  const [activeTab, setActiveTab] = useState(0);

  const handleTabClick = (index) => {
    setActiveTab(index);
  };

  return (
    <Layout>
      <InnerPageBanner title='Investment Plan' innerPageBanner={ImageIcons.contactBg} />
      <section className='investment relative'>
        <div className='absolute w-[220px] left-[20px] bottom-[20px] animate-[animate-bounceTop_5s_infinite_ease-in-out_alternate] max-lg:opacity-70'>
          <img className='object-cover rotate-180' src={ImageIcons.aboutShape} alt='Shape' />
        </div>
        <div className='container'>
          <div className=''>
            <div className='text-center'>
              <div className='text-[#f48533] mb-[5px] '>That you'll be proud to make</div>
              <h2>An <span className='text-[#f48533]'>Investment</span> Decision</h2>
            </div>
            <div className="invest-container mt-[40px]">
              <div id="icetab-container" className='mb-[30px] text-center flex justify-between max-md:flex-wrap xl:gap-[20px] md:gap-[12px] gap-[10px]'>
                {tabHeader.map((item, i) => (
                  <div
                    key={i}
                    className={`icetab w-full max-md:w-[48%] cursor-pointer inline-block px-[10px] py-[20px] shadow-[0px_3px_15px_0px_rgba(0,0,0,0.1)] rounded-[10px] ${i === activeTab ? 'current-tab bg-[linear-gradient(157deg,#f48533_0%,#3308d3_100%)] !text-white' : ''}`}
                    onClick={() => handleTabClick(i)}
                  >
                    <div className={`lg:text-[45px] text-[35px] mx-auto flex justify-center mb-[10px] text-[#f48533] ${i === activeTab ? 'text-white' : ''}`}>{item.headerIcon}</div>
                    <h4 className='mb-0 xl:text-[18px] lg:text-[17px] text-[15px]'>{item.headerTitle}</h4>
                  </div>
                ))}
              </div>

              <div className='overflow-hidden relative lg:pt-[30px] pt-[10px]' id="icetab-content">
                {PlansData.map((item, i) => (
                  <div
                    key={i}
                    className={`tabcontent w-full   opacity-0 overflow-hidden h-0 ${i === activeTab ? 'opacity-100 overflow-visible h-auto' : ''}`}
                  >
                    <div className='grid lg:grid-cols-2 grid-cols-1 xl:gap-[50px] gap-[40px] items-center'>
                      <div className=' '>
                        <h3 className='mb-[5px]'>{item.title}</h3>
                        <div>{item.text}</div>
                        <div className='mt-[20px] mb-[20px]'>
                          <p>{item.description}</p>
                        </div>

                        {item.tabheader && <ul className='mb-[25px] grid sm:grid-cols-2 grid-cols-1 gap-[12px]'>
                          {item.tabheader.map((item, i) => (
                            <li className='mb-[6px] text-[16px] relative pl-[25px]'><IoCheckmarkDoneSharp className='text-[17px] inline-block text-[#f48533] absolute left-0 top-[5px]' /> {item}</li>
                          ))}
                        </ul>
                        }
                        <Button hrefLink={item.handle} title='Get in touch' />
                      </div>

                      <div className='max-w-[700px] mx-auto'>
                        <img src={ImageIcons.investment} alt='Investment' />
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className='relative border border-[#fcecdc]'>
        <div className='absolute max-lg:hidden w-[250px] right-0 bottom-[20px] animate-[animate-bounceTop_5s_infinite_ease-in-out_alternate] max-lg:opacity-30 opacity-40'>
          <img className='object-cover' src={ImageIcons.inneraboutShape} alt='Shape' />
        </div>
        <div className='container'>
          <div className='text-center lg:mb-[40px] mb-[25px]'>
            <div className='text-[#f48533] mb-[5px]'>Let's create an Impact</div>
            <h2>Philanthropy</h2>
          </div>
          <div className='grid lg:grid-cols-2 grid-cols-1 lg:gap-[50px] gap-[40px] items-center'>
            <div className='max-lg:order-2'>
              <img className='mx-auto' src={ImageIcons.philanthropy} alt='Philanthropy' />
            </div>
            <div className='max-lg:order-1'>
              <p>Business is not just about balance sheets and revenues, we
                believe in human values of kindness and compassion. We at
                my Internet believe in giving back to the society by being
                empathetic to the underprivileged. Our theme "Each One
                Teach One"- education is the only way to eradicate poverty
                from the world</p>

              <ul>
                <li className='relative pl-[25px] mb-[10px]'><IoCheckmarkDoneSharp className='text-[17px] inline-block text-[#f48533] absolute left-0 top-[5px]' />We are planning to adopt 36 children in India, one each in every
                  state and UT. <br /> We will ensure the best for them, will support them in making a
                  mark in the society in their streams as education, Sports,
                  Entertainment and will ask them to pledge to adopt 36 children
                  further to continue the initiative started by My Internet.</li>
                <li className='relative pl-[25px] mb-[10px]'><IoCheckmarkDoneSharp className='text-[17px] inline-block text-[#f48533] absolute left-0 top-[5px]' />Annual City Ball</li>
                <li className='relative pl-[25px] mb-[10px]'><IoCheckmarkDoneSharp className='text-[17px] inline-block text-[#f48533] absolute left-0 top-[5px]' />Annual Unity Concerts and March</li>
                <li className='relative pl-[25px] mb-[10px]'><IoCheckmarkDoneSharp className='text-[17px] inline-block text-[#f48533] absolute left-0 top-[5px]' />United Flag Streets</li>
              </ul>
            </div>
          </div>
        </div>
      </section>

    </Layout>
  )
}

const tabHeader = [
  {
    headerTitle: "Merger & Aquisition",
    headerIcon: <FaHandshake />
  },
  {
    headerTitle: "OTT",
    headerIcon: <SiPicartodottv />
  },
  {
    headerTitle: "Expansion",
    headerIcon: <FaExpandArrowsAlt />
  },
  {
    headerTitle: "Philanthropy",
    headerIcon: <FaHandHoldingHeart />
  },
  {
    headerTitle: "Business & Revenue",
    headerIcon: <FaSackDollar />
  },
]

const PlansData = [
  {
    title: "Mergers & Aquisition",
    text: "28 States - 8 ut",
    description: "Our marketing team and infra set-up shall extend further reach within the state to acquire more connections. after initial set up in residential and commercial sector like banks/universities, schools, hot spots. the official shall run on self-sustainable mode.",
    handle: "/contact-us",
  },
  {
    title: "OTT",
    description: "Growth of ott service market and change in the structure of the pay-tv market being a grade a isp, we decided to bundle down as much ott streaming platforms as vas (value added services) for our end user. we are in discussions with all the major ott platforms in india to bundle with our isp.",
    handle: "/contact-us",
  },
  {
    title: "Expansion",
    text: "Pan India",
    description: "We are proud to be expanding pan-india with merger and acquisition in every state and city of india. investment required for:",
    handle: "/contact-us",
  },
  {
    title: "Philanthropy",
    description: "Philanthropy is a major part of marketing & branding, endorsements and sponsoring national events like ipl. with internet being a part of everybody's lives, we need to target both masses and classes, youth and adult, children and old age, endorsements, promotional videos, social media needs to be taken care with 360 degree marketing.",
    handle: "/contact-us",
  },
  {
    title: "Business and Revenue",
    description: "5000 connections/ state by end of finanacial year 2021. project revenue - $500 musd - by 2025",
    handle: "/contact-us",
    tabheader:
      ["Infrastructure- office set up", "Manpower-retaining best in the market", "R & D Labs", "High end customer support, Chat bots", "​24 X 7 hours call centre", "Servers & data centre", "Laying our own Fibre wire & equipment", "Headend to support the infrastructure"]
  },
]

export default Invest;