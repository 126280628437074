import React, { useState, useRef, useEffect } from 'react';
import Layout from '../components/shared/layout';
import InnerPageBanner from '../components/shared/innerPageBanner';
import ImageIcons from '../components/imageComponent/ImageIcons';
import axios from "axios";
import { FaCheckCircle } from "react-icons/fa";
import Loader from "../components/shared/loader";
import { FaMinus } from "react-icons/fa";
import { FaPlus } from "react-icons/fa";
import { IoCheckmarkDoneSharp } from 'react-icons/io5';
import { MdRadioButtonChecked } from 'react-icons/md';
import SEO from '../components/shared/seo';

const Franchisee = () => {
  const [activeIndex, setActiveIndex] = useState(null);
  
  const [disabled, setDisabled] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    phone: '',
    city: '',
    district: '',
    state: '',
    query: ''
  });

  const [errors, setErrors] = useState({
    name: '',
    phone: '',
    city: '',
    district: '',
    state: '',
    query: ''

  });

  const firstNameHandler = (e) => {
    const inputValue = e.target.value.trim(); // Remove leading and trailing spaces
    const alphaValue = inputValue.replace(/[^A-Za-z ]/g, ''); // Allow spaces

    // Capitalize the first letter of each word
    const capitalizedValue = alphaValue.replace(/\b\w/g, (match) => match.toUpperCase());


    if (capitalizedValue.length >= 30) {
      setErrors((prevState) => ({ ...prevState, name: "Full Name must be less than or equal to 30 characters" }));
    } else {
      setFormData((prevState) => ({ ...prevState, name: capitalizedValue }));
      setErrors((prevState) => ({ ...prevState, name: "" }));
    }
  };

  const handlePhoneInputChange = (e) => {
    const inputValue = e.target.value.trim();
    const numericValue = inputValue.replace(/\D/g, ""); // Remove non-digit characters

    if (numericValue.length <= 10) {
      setFormData((prevState) => ({ ...prevState, phone: numericValue }));
      setErrors((prevState) => ({ ...prevState, phone: false }));
    }

  }

  const cityHandler = (e) => {
    const inputValue = e.target.value.trim();
    const alphaValue = inputValue.replace(/[^A-Za-z ]/g, ""); // Allow spaces
    // Capitalize the first letter of each word
    const capitalizedValue = alphaValue.replace(/\b\w/g, (match) =>
      match.toUpperCase()
    );
    if (capitalizedValue.length <= 20) {
      setErrors((prevState) => ({ ...prevState, city: "city must be less than or equal to 20 characters" }));

      console.log("alphaValue", alphaValue);
      setFormData((prevState) => ({ ...prevState, city: capitalizedValue }));
      setErrors((prevState) => ({ ...prevState, city: " " }));
    }

  };
  const stateHandler = (e) => {
    const inputValue = e.target.value;
    const alphaValue = inputValue.replace(/[^A-Za-z ]/g, ""); // Allow spaces
    // Capitalize the first letter of each word
    const capitalizedValue = alphaValue.replace(/\b\w/g, (match) =>
      match.toUpperCase()
    );
    if (capitalizedValue.length <= 20) {
      setErrors((prevState) => ({ ...prevState, state: "state must be less than or equal to 20 characters" }));
      console.log("alphaValue", alphaValue);
      setFormData((prevState) => ({ ...prevState, state: capitalizedValue }));
      setErrors((prevState) => ({ ...prevState, state: " " }));
    }
  };
  const districtHandler = (e) => {
    const inputValue = e.target.value;
    const alphaValue = inputValue.replace(/[^A-Za-z ]/g, ""); // Allow spaces
    // Capitalize the first letter of each word
    const capitalizedValue = alphaValue.replace(/\b\w/g, (match) =>
      match.toUpperCase()
    );
    if (capitalizedValue.length <= 20) {
      console.log("alphaValue", alphaValue);
      setErrors((prevState) => ({ ...prevState, district: "district must be less than or equal to 20 characters" }));
      setFormData((prevState) => ({ ...prevState, district: capitalizedValue }));
      setErrors((prevState) => ({ ...prevState, district: " " }));
    }
  };

  const handlePincodeChange = (e) => {
    const inputValue = e.target.value;
    const numericValue = inputValue.replace(/\D/g, "");

    if (numericValue.length <= 6) {
      setFormData((prevState) => ({ ...prevState, pincode: numericValue }));
      setErrors((prevState) => ({ ...prevState, pincode: false, message: "" }));
    }
  };

  const validateForm = () => {
    let newErrors = {};
    setDisabled(true);
    if (!formData.name) newErrors.name = 'Name is required';
    if (!formData.phone) newErrors.phone = 'PhoneNumber is required';
    if (!formData.city) newErrors.city = 'City is required';
    if (!formData.district) newErrors.district = 'District is required';
    if (!formData.state) newErrors.state = 'State is required'; // Validation for logo
    if (!formData.pincode) newErrors.pincode = 'Pincode is required'; // Validation for logo
    if (!formData.query) newErrors.query = 'Query is required'; // Validation for logo

    setErrors(newErrors);
    setDisabled(false);
    return Object.keys(newErrors).length === 0;
  };
  const validRegex = /^(?!.*(\d)\1{9})[6-9]\d{9}$/;
  // const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  const handleSubmit = async (event) => {
    event.preventDefault();
    // setDisabled(true);
    if (!validateForm()) {
      console.log('Validation Failed');
      return;
    } else if (formData.phone.length < 10) {
      setErrors((prevState) => ({
        ...prevState,
        // phoneNumber: true,
        phone: "Phone number is invalid!",
      }));
      setDisabled(false);
    } else if (!validRegex.test(formData.phone)) {
      setErrors((prevState) => ({
        ...prevState,
        // phone: true,
        phone: "Phone number must be valid !",
      }));
      setDisabled(false);

    } else if (formData.pincode.length < 6) {
      setErrors((prevState) => ({
        ...prevState,
        pincode: "Pincode is invalid!"
      }));
      setDisabled(false);
    } else if (formData.pincode === '000000') {
      setErrors((prevState) => ({
        ...prevState,
        pincode: "Pincode cannot be 0. Please enter a valid pincode."
      }));
      setDisabled(false);
    } else if (formData.pincode.charAt(0) === '0') {
      setErrors((prevState) => ({
        ...prevState,
        pincode: "Pincode cannot start with '0'. Please enter a valid pincode.",
      }));

      setDisabled(false);
    }
    else {
      setDisabled(true);
      try {
        const response = await axios.post(`${process.env.REACT_APP_URL}user/addfranchisee`,
          {
            name: formData.name,
            phone: formData.phone,
            city: formData.city,
            district: formData.district,
            state: formData.state,
            pincode: formData.pincode,
            query: formData.query,

          }
        );

        if (response.data) {
          setTimeout(() => {
            setErrors((prevState) => ({ ...prevState, success: false }));
            setDisabled(false);
            setFormData({
              name: '',
              phone: '',
              city: '',
              state: '',
              district: '',
              pincode: '',
              query: ''
            });

          }, 3000);

        }
        setErrors((prevState) => ({ ...prevState, success: true }));
      }
      catch (errors) {
        console.log('Error adding customer:', errors);
        setErrors(prevState => ({ ...prevState, message: errors.response }));
        setDisabled(false);
      }
    }
  };

  const handleToggle = (index) => {
    setActiveIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  return (
    <>
    <SEO title='Become ISP Franchisee Partner with My Internet' description='Join My Internet as a franchisee partner and provide top-tier internet services in your area. Unlock growth potential with our proven business model and support.' keywords='ISP franchisee opportunity,Internet service provider franchisee,Affordable ISP franchisee,ISP franchisee,My Internet franchisee,Become a franchisee partner,ISP business model,Franchisee internet services,Partner with My Internet,ISP franchisee support,Internet franchisee growth,Best ISP franchisee,ISP franchisee benefits,Franchisee with My Internet,Internet service franchisee opportunity,'/>
    <Layout>
      <InnerPageBanner headingH2='headingH2' title='Franchisee' innerPageBanner={ImageIcons.Franchisee} />
 
      <div className='container flex max-lg:flex-wrap flex-row-reverse justify-between'>
        <div className='lg:w-[400px] w-full'>
          <section className='sticky top-[50px] right-0 w-full max-lg:pb-0'>
            {errors.success ? (
              <div className="py-24 items-center m-[20px]">
                <div className="text-center py-10">
                  <h2>Thank You For Information</h2>
                  <FaCheckCircle
                    sx={{ fontSize: "400px", color: "#008000" }}
                    className=" ml-[670px] mt-[100px] text-7xl " />
                  <p className="text-[30px] text-[#f38432] font-bold mt-[100px]">
                    I hope we can solve your Query
                  </p>
                </div>
              </div>
            ) : (
              <>
                <div className='relative'>
                  <form className='shadow-[0px_0px_30px_0px_rgba(0,0,0,0.15)] p-[30px] rounded-[10px] grid grid-cols-1 items-center gap-[13px]' onSubmit={handleSubmit}>
                    {disabled && <Loader />}
                    <div>
                      <h3 className='mb-[5px] text-[26px] leading-[26px]'><span className='text-[#f48533]'>Franchisee</span> Query</h3>
                    </div>
                    <div>
                      {/* <label className='mb-[2px] inline-block' htmlFor='name'>Name</label> */}
                      <input
                        placeholder='Name'
                        id="name"
                        name="name"
                        type="text"
                        maxLength={30}
                        disabled={disabled}
                        value={formData.name}
                        onChange={firstNameHandler}
                        className={`w-full h-[45px] border border-[#f7dfcd] placeholder:text-[#626262] bg-[#fff] px-[15px] rounded-[5px] focus:outline-none focus:border-[#f48533] focus:bg-[#fbf2ee] ${errors.name && 'border-red-500'}`}
                      />
                      {errors.name && <p className="text-red-500 text-sm mb-0 mt-[2px]">{errors.name}</p>}
                    </div>
                    <div>
                      {/* <label className='mb-[2px] inline-block' htmlFor='Phone Number'></label> */}
                      <input
                        placeholder='Phone Number'
                        id="phone"
                        name="phone"
                        type="text"
                        disabled={disabled}
                        value={formData.phone}
                        onChange={handlePhoneInputChange}
                        maxLength={10}
                        className={`w-full h-[45px] border border-[#f7dfcd] placeholder:text-[#626262] bg-[#fff] px-[15px] rounded-[5px] focus:outline-none focus:border-[#f48533] focus:bg-[#fbf2ee] ${errors.name && 'border-red-500'}`}
                      />
                      {errors.phone && <p className="text-red-500 text-sm mb-0 mt-[2px]">{errors.phone}</p>}
                    </div>
                    <div>
                      {/* <label className='mb-[2px] inline-block' htmlFor='city'>City</label> */}
                      <input
                        placeholder='City'
                        id="city"
                        name="city"
                        type="text"
                        disabled={disabled}
                        value={formData.city}
                        onChange={cityHandler}
                        className={`w-full h-[45px] border border-[#f7dfcd] placeholder:text-[#626262] bg-[#fff] px-[15px] rounded-[5px] focus:outline-none focus:border-[#f48533] focus:bg-[#fbf2ee] ${errors.city && 'border-red-500'}`}
                      />
                      {errors.city && <p className="text-red-500 text-sm mb-0 mt-[2px]">{errors.city}</p>}
                    </div>
                    {/* Similar blocks for district, state, and query inputs */}
                    {/* District */}
                    <div>
                      {/* <label className='mb-[2px] inline-block' htmlFor='district'>District</label> */}
                      <input
                        placeholder='District'
                        id="district"
                        name="district"
                        type="text"
                        disabled={disabled}
                        value={formData.district}
                        onChange={districtHandler}
                        className={`w-full h-[45px] border border-[#f7dfcd] placeholder:text-[#626262] bg-[#fff] px-[15px] rounded-[5px] focus:outline-none focus:border-[#f48533] focus:bg-[#fbf2ee] ${errors.district && 'border-red-500'}`}
                      />
                      {errors.district && <p className="text-red-500 text-sm mb-0 mt-[2px]">{errors.district}</p>}
                    </div>
                    {/* State */}
                    <div>
                      {/* <label className='mb-[2px] inline-block' htmlFor='state'>State</label> */}
                      <input
                        placeholder='State'
                        id="state"
                        name="state"
                        type="text"
                        disabled={disabled}
                        value={formData.state}
                        onChange={stateHandler}
                        className={`w-full h-[45px] border border-[#f7dfcd] placeholder:text-[#626262] bg-[#fff] px-[15px] rounded-[5px] focus:outline-none focus:border-[#f48533] focus:bg-[#fbf2ee] ${errors.state && 'border-red-500'}`}
                      />
                      {errors.state && <p className="text-red-500 text-sm mb-0 mt-[2px]">{errors.state}</p>}
                    </div>
                    <div>
                      {/* <label className='mb-[2px] inline-block' htmlFor='state'>Pincode</label> */}
                      <input
                        placeholder='Pincode'
                        id="pincode"
                        name="pincode"
                        type="text"
                        disabled={disabled}
                        value={formData.pincode}
                        onChange={handlePincodeChange}
                        className={`w-full h-[45px] border border-[#f7dfcd] placeholder:text-[#626262] bg-[#fff] px-[15px] rounded-[5px] focus:outline-none focus:border-[#f48533] focus:bg-[#fbf2ee] ${errors.state && 'border-red-500'}`}
                      />
                      {errors.pincode && <p className="text-red-500 text-sm mb-0 mt-[2px]">{errors.pincode}</p>}
                    </div>
                    {/* Existing Business Query */}
                    <div>
                      {/* <label className='mb-[2px] inline-block' htmlFor='query'></label> */}
                      <textarea
                        placeholder='Existing Business Query'
                        id='query'
                        name='query'
                        disabled={disabled}
                        value={formData.query}
                        onChange={(e) => {
                          setFormData((prevState) => ({
                            ...prevState,
                            query: e.target.value,
                          }));
                          setErrors((prevState) => ({
                            ...prevState,
                            query: false,
                            message: "",
                          }));
                        }} maxLength={100}
                        className={`w-full h-[65px] py-[10px] border placeholder:text-[#626262] border-[#f7dfcd] bg-[#fff] px-[15px] rounded-[5px] focus:outline-none focus:border-[#f48533] focus:bg-[#fbf2ee] ${errors.query && 'border-red-500'}`}
                      />
                      {errors.query && <p className="text-red-500 text-sm mb-0 mt-[2px]">{errors.query}</p>}

                    </div>
                    {/* Submit Button */}
                    <div className='text-center'>
                      <button className='px-[45px] h-[45px] w-full duration-500 font-medium text-[#fff] bg-[#f48533] rounded-[10px] hover:bg-[#000] ease inline-block' type='submit' >Submit</button>
                    </div>
                  </form>
                </div>
              </>
            )}
          </section>
        </div>

        <div className='lg:w-[calc(100%_-_400px_-_50px)]'>
          <section className='pb-0'>
            <p className="relative mb-0 font-normal shadow-[0px_2px_10px_#ddd] p-[35px] after:absolute after:content-[''] after:w-[50px] after:hover:w-[calc(100%_+_10px)] after:h-[50px] after:hover:h-[calc(100%_+_10px)] after:duration-700 after:border-r-[4px] after:border-r-[#e35f21] after:border-b-[4px] after:border-b-[#e35f21] after:border-solid after:right-0 after:bottom-0  before:absolute before:content-[''] before:w-[50px] before:hover:w-[calc(100%_+_10px)] before:h-[50px] before:hover:h-[calc(100%_+_10px)] before:duration-700 before:border-l-[4px] before:border-l-[#e35f21] before:border-t-[4px] before:border-t-[#e35f21] before:border-solid before:left-0 before:top-0">Welcome to My Internet, which has transformed today’s digital landscape by providing reliable and high-speed internet connectivity. We are devoted to creating a world where everyone enjoys access to seamless and affordable internet so that communities can be empowered and their growth can be fostered. As we are committed to expanding our services, we are excited to offer our franchisee opportunities with the people who are passionate towards connectivity and innovation.</p>
          </section>

          <section className='md:pt-[50px] pt-[40px] pb-0'>
            <div>
              <h1>Franchisee Opportunities</h1>
              <p className='mb-0'>My Internet is offering exclusive Franchisee opportunities to both individuals and businesses who are looking to join our services. By choosing our services, you will become a part of a growing industry, as you will be backed by a trusted and esteemed brand especially known for its excellent and quality services.</p>

              <img src={ImageIcons.franchiseeImg} alt='Franchisee' className='w-full mt-[30px]' />
            </div>
          </section>

          <section className='md:pt-[50px] pt-[40px] pb-0'>
            <div>
              <h2>Our Vision</h2>

              <p className='mb-0'>We, at MyInternet, have a vision to completely eliminate the digital gap by making high-speed internet accessible to all irrespective of their location.  The internet is no less than a fundamental right in today’s era, as connectivity to it has become utmost for the purpose of education, communication, and business. If we expand our network, we can easily fulfil our aim to provide our services in remote areas and in every corner of the globe.</p>
            </div>
          </section>

          <section className='md:pt-[50px] pt-[40px] pb-0'>
            <div>
              <h2>What are the Benefits of Becoming Our Franchisee Partner?</h2>

              <p>If you become our franchisee partner, you will get multiple advantages which are mentioned below.</p>

              <ul className='mt-[15px]'>
                <li className='mb-[8px] text-[16px] relative pl-[25px]'><IoCheckmarkDoneSharp className='text-[17px] inline-block text-[#f48533] absolute left-0 top-[5px]' />As we have a proven business model, you will be able to leverage it to kickstart your venture.</li>
                <li className='mb-[8px] text-[16px] relative pl-[25px]'><IoCheckmarkDoneSharp className='text-[17px] inline-block text-[#f48533] absolute left-0 top-[5px]' />We will provide you with extensive training and support needed to run the franchisee like technical operations, customer services and much more.</li>
                <li className='mb-[8px] text-[16px] relative pl-[25px]'><IoCheckmarkDoneSharp className='text-[17px] inline-block text-[#f48533] absolute left-0 top-[5px]' />With us, you can get easy access to our marketing resources and other campaigns which will help you promote your franchisee.</li>
                <li className='mb-0 text-[16px] relative pl-[25px]'><IoCheckmarkDoneSharp className='text-[17px] inline-block text-[#f48533] absolute left-0 top-[5px]' />We ensure providing our franchisee partners with consistent support through our team of professionals so that you can operate your business smoothly.</li>
              </ul>
            </div>
          </section>

          <section className='md:pt-[50px] pt-[40px] pb-0'>
            <div>
              <h2>Our Brand USP</h2>

              <ul className='mt-[15px] list-decimal	pl-[20px]'>
                <li className='mb-[8px]'>With us, you can become an ISP with low-investment.</li>
                <li className='mb-[8px]'>You will get an opportunity to be a part of a reputed and established ISP network.</li>
                <li className='mb-[8px]'>You will be able to enjoy the competitive tariffs in the ISP industry.</li>
                <li className='mb-0'>Get experience of best ROI along with incentives available for the ISP franchisee in India.</li>
              </ul>
            </div>
          </section>

          <section className='md:pt-[50px] pt-[40px] pb-0'>
            <div>
              <h2>Franchisees\ Requirements</h2>
              <h4>Location Specifications</h4>

              <ul className='mt-[15px]'>
                <li className='mb-[8px] text-[16px] relative pl-[25px]'><MdRadioButtonChecked className='text-[17px] inline-block text-[#f48533] absolute left-0 top-[5px]' />High-traffic commercial areas</li>
                <ul className='mt-[15px] pl-[20px] mb-[15px]'>
                  <li className='mb-[8px] text-[16px] relative pl-[25px]'><IoCheckmarkDoneSharp className='text-[17px] inline-block text-[#f48533] absolute left-0 top-[5px]' />High-traffic commercial areas</li>
                  <li className='mb-[8px] text-[16px] relative pl-[25px]'><IoCheckmarkDoneSharp className='text-[17px] inline-block text-[#f48533] absolute left-0 top-[5px]' />Residential neighbourhood</li>
                  <li className='mb-[8px] text-[16px] relative pl-[25px]'><IoCheckmarkDoneSharp className='text-[17px] inline-block text-[#f48533] absolute left-0 top-[5px]' />Underserved regions</li>
                </ul>

                <li className='mb-[8px] text-[16px] relative pl-[25px]'><MdRadioButtonChecked className='text-[17px] inline-block text-[#f48533] absolute left-0 top-[5px]' />You need to have basic understanding regarding business operations and management.</li>
                <li className='mb-[8px] text-[16px] relative pl-[25px]'><MdRadioButtonChecked className='text-[17px] inline-block text-[#f48533] absolute left-0 top-[5px]' />Although it is not mandatory to technical skills, basic internet understanding is beneficial.</li>
                <li className='mb-[8px] text-[16px] relative pl-[25px]'><MdRadioButtonChecked className='text-[17px] inline-block text-[#f48533] absolute left-0 top-[5px]' />You need to have access to necessary equipment and a team of few members so that installation and customer support can be handled.</li>
                <li className='mb-[8px] text-[16px] relative pl-[25px]'><MdRadioButtonChecked className='text-[17px] inline-block text-[#f48533] absolute left-0 top-[5px]' />Further, you have to sign the agreement which will include details regarding terms and conditions of our partnership.</li>
                <li className='mb-[8px] text-[16px] relative pl-[25px]'><MdRadioButtonChecked className='text-[17px] inline-block text-[#f48533] absolute left-0 top-[5px]' />You will have to comply with all the regulations like getting permits and licences.</li>
                <li className='mb-0 text-[16px] relative pl-[25px]'><MdRadioButtonChecked className='text-[17px] inline-block text-[#f48533] absolute left-0 top-[5px]' />You need to have insurance, so that you can safeguard your business and its operations.</li>
              </ul>
            </div>
          </section>

          <section className='md:pt-[50px] pt-[40px] pb-0'>
            <div>
              <h2>Support and Training</h2>
              <p>Users are provided a  proper support and training so that franchisee can be established successfully-</p>

              <ul className='mt-[15px]'>
                <li className='mb-[8px] text-[16px] relative pl-[25px]'><IoCheckmarkDoneSharp className='text-[17px] inline-block text-[#f48533] absolute left-0 top-[5px]' />In the initial training, we will cover technical operations, customer service and business management techniques.</li>
                <li className='mb-[8px] text-[16px] relative pl-[25px]'><IoCheckmarkDoneSharp className='text-[17px] inline-block text-[#f48533] absolute left-0 top-[5px]' />We ensure consistent support through our team of experts which ensures smooth operations and growth.</li>
                <li className='mb-[8px] text-[16px] relative pl-[25px]'><IoCheckmarkDoneSharp className='text-[17px] inline-block text-[#f48533] absolute left-0 top-[5px]' />You will be provided with access to marketing materials, promotional campaigns and branding resources.</li>
                <li className='mb-0 text-[16px] relative pl-[25px]'><IoCheckmarkDoneSharp className='text-[17px] inline-block text-[#f48533] absolute left-0 top-[5px]' />We have an expert and dedicated team of professionals which will help you with all the technical issues and other queries.</li>
              </ul>
            </div>
          </section>

          <section className='md:pt-[50px] pt-[40px] pb-0'>
            <div>
              <h2>Application Process</h2>

              <ul className='mt-[15px] list-decimal	pl-[20px]'>
                <li className='mb-[8px]'>Firstly, contact our team via email or phone.</li>
                <li className='mb-[8px]'>Complete the franchisee application and then submit it. You will get it on our website.</li>
                <li className='mb-[8px]'>Your application will be reviewed by our team and they will conduct initial screening.</li>
                <li className='mb-[8px]'>The qualified candidates will be called for an interview in order to discuss the details.</li>
                <li className='mb-[8px]'>Qualified ones will get approval notification along with our agreement.</li>
                <li className='mb-[8px]'>The onboarding process will be started.</li>
                <li className='mb-[8px]'>It usually takes around 2 weeks to review the application.</li>
                <li className='mb-[8px]'>Call for interview and approval takes around 3-4 weeks.</li>
                <li className='mb-0'>In 4-6 weeks, the onboarding and training process will be started.</li>
              </ul>
            </div>
          </section>

          <section className='md:pt-[50px] pt-[504x]'>
            <div>
              <div className="mx-auto bg-white rounded-lg">

                {faqs.map((faq, index) => (
                  <AccordionItem
                    key={index}
                    active={activeIndex === index}
                    handleToggle={() => handleToggle(index)}
                    faq={faq}
                  />
                ))}
              </div>
            </div>
          </section>
        </div>
      </div>
    </Layout>
    </>
  )
}

const AccordionItem = ({ handleToggle, active, faq }) => {
  const contentRef = useRef(null);
  const [height, setHeight] = useState('0px');

  useEffect(() => {
    if (contentRef.current) {
      setHeight(active ? `${contentRef.current.scrollHeight}px` : '0px');
    }
  }, [active]);

  return (
    <div className="mb-[20px] last:mb-0 shadow-[0px_3px_15px_0px_rgba(0,0,0,0.1)] overflow-hidden rounded-[10px]">
      <div
        className={`p-[20px] flex justify-between items-center cursor-pointer ${active ? 'bg-[#f48533] text-white' : ''}`}
        onClick={handleToggle}
      >
        <h5 className="mb-0 lg:text-[20px] text-[18px] ">{faq.question}</h5>
        <span>{active ? <FaMinus /> : <FaPlus />}</span>
      </div>
      <div
        ref={contentRef}
        className={`overflow-hidden transition-all duration-300`}
        style={{ maxHeight: height }}
      >
        <div className="p-[20px] ">
          <p className='mb-0'>{faq.answer}</p>

          {faq.answerList && <ul className='mt-[15px]'>
            {faq.answerList.map((faq, i) => (
              <li className='mb-[4px] text-[16px] relative pl-[25px]'><IoCheckmarkDoneSharp className='text-[17px] inline-block text-[#f48533] absolute left-0 top-[5px]' /> {faq}</li>
            ))}
          </ul>
          }
        </div>
      </div>
    </div>
  );
};

const faqs = [
  {
    question: "Do I need any technical expertise to run the franchisee?",
    answer: "Although it is not mandatory, having a bit of technical knowledge would be beneficial."
  },
  {
    question: "How much time does it take for approval?",
    answer: "It takes around 3-4 weeks."
  },
  {
    question: "What kind of support would be provided by you?",
    answer: "We offer every kind of support including-",
    answerList:
      ["Blazing-fast speed", "Ongoing assistance", "Marketing support", "Technical support"]
  }
];
export default Franchisee;