import React from 'react'
import Layout from '../components/shared/layout'
import InnerPageBanner from '../components/shared/innerPageBanner'
import ImageIcons from '../components/imageComponent/ImageIcons'
import { IoCheckmarkDoneSharp } from 'react-icons/io5'

const RefundPolicy = () => {
  return (
    <Layout>
      <InnerPageBanner title='Refund Policy' innerPageBanner={ImageIcons.RefundPolicy} />
      <section>
        <div className='container'>
          <p>There are certain times when things do not go according to the plan and in that case, you may need a refund. So, for your convenience, we encourage you to read this carefully</p>

          <h4>Eligibility for Refunds</h4>
          <p>You are eligible for the refund if</p>

          <ul className='mb-[20px]'>
            <li className='mb-[6px] text-[16px] relative pl-[25px]'><IoCheckmarkDoneSharp className='text-[17px] inline-block text-[#f48533] absolute left-0 top-[5px]' />If you are facing continuous technical problems which we have been unable to resolve.</li>
            <li className='mb-[6px] text-[16px] relative pl-[25px]'><IoCheckmarkDoneSharp className='text-[17px] inline-block text-[#f48533] absolute left-0 top-[5px]' />If we have charged you incorrectly or for any extra service which you have not used.</li>
          </ul>

          <h4>How to Request a Refund</h4>
          <p>In order to get a refund</p>

          <ul className='mb-[20px]'>
            <li className='mb-[6px] text-[16px] relative pl-[25px]'><IoCheckmarkDoneSharp className='text-[17px] inline-block text-[#f48533] absolute left-0 top-[5px]' />Contact our customer support team.</li>
            <li className='mb-[6px] text-[16px] relative pl-[25px]'><IoCheckmarkDoneSharp className='text-[17px] inline-block text-[#f48533] absolute left-0 top-[5px]' />You need to give us your account details along with detailed reason and evidence of the problem.</li>
            <li className='mb-[6px] text-[16px] relative pl-[25px]'><IoCheckmarkDoneSharp className='text-[17px] inline-block text-[#f48533] absolute left-0 top-[5px]' />Your details will be reviewed and we will contact you back within 5-7 business days.</li>
          </ul>

          <h4>Refund Methods</h4>
          <p>On the approval of the refund, you will get payment via-</p>

          <ul className='mb-[20px]'>
            <li className='mb-[6px] text-[16px] relative pl-[25px]'><IoCheckmarkDoneSharp className='text-[17px] inline-block text-[#f48533] absolute left-0 top-[5px]' />Your original method of  payment.</li>
            <li className='mb-[6px] text-[16px] relative pl-[25px]'><IoCheckmarkDoneSharp className='text-[17px] inline-block text-[#f48533] absolute left-0 top-[5px]' />The processing time would be between 5 and 10 business needs.</li>
          </ul>

          <h4>Special Cases</h4>
          <p>The special cases are being handled with utmost care-</p>

          <ul className='mb-[20px]'>
            <li className='mb-[6px] text-[16px] relative pl-[25px]'><IoCheckmarkDoneSharp className='text-[17px] inline-block text-[#f48533] absolute left-0 top-[5px]' />If you have received any promotional discount, the refund amount will be adjusted accordingly.</li>
            <li className='mb-[6px] text-[16px] relative pl-[25px]'><IoCheckmarkDoneSharp className='text-[17px] inline-block text-[#f48533] absolute left-0 top-[5px]' />The prorated amount will be calculated in case you are eligible for partial refund.</li>
          </ul>

          <h4>Non-Refundable Charges</h4>
          <p>There are certain charges which are non-refundable-</p>

          <ul className='mb-[20px]'>
            <li className='mb-[6px] text-[16px] relative pl-[25px]'><IoCheckmarkDoneSharp className='text-[17px] inline-block text-[#f48533] absolute left-0 top-[5px]' />Installation fees are non-refundable on the completion of the service.</li>
            <li className='mb-[6px] text-[16px] relative pl-[25px]'><IoCheckmarkDoneSharp className='text-[17px] inline-block text-[#f48533] absolute left-0 top-[5px]' />Usage based fees are also non-refundable.</li>
          </ul>


        </div>
      </section>
    </Layout>
  )
}

export default RefundPolicy