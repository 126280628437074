import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { useEffect, useState } from 'react';
import './App.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Home from './pages/home';
import Contact from './pages/contact';
import About from './pages/about';
import Plans from './pages/plans';
import Vision from './pages/vision';
import Invest from './pages/invest';
import Broadband from './pages/broadband';
import Blog from './pages/blogs';
import Career from './pages/career';
// import Register from './pages/register';
import BlogDetails from './pages/blog-details';
import PrivacyPolicy from './pages/privacy-policy';
import TermsConditions from './pages/terms-conditions';
// import Login from './pages/login';
import RefundPolicy from './pages/refund-policy';
import AccountContext from "./utils/AccountContext"
// import CustomerForm from './pages/customer-form';
import NotFoundPage from './pages/404';
import Franchisee from './pages/franchisee';
import PrivateRoutes from './utils/PrivateRoutes'
import Disclaimer from './pages/disclaimer';
import FAQ from './pages/faq';
import ServicesDetails from './pages/service';
import City from './pages/cities';
import CityDetails from './pages/cities-detail';
import Pricing from './pages/pricing'; import Searchs from './pages/search';
import SiteMap from './pages/sitemap';
import Thanks from './pages/thanks';
import GetMyInternet from './pages/get-myinternet';
// import Customersubmit from './pages/customer-submit';
// const auth = ["/thanks","*","/pricing/:handle","/search","/sitemap","/contact-us", "/about-us", "/plans", "/vision", "/invest", "/pricing", "/blog", "/career", "/franchisee", "/privacy-policy", "/refund-policy", "/terms-conditions", "/blog/:handle", "/customer", "/faqs", '/service/internet-leased-line', '/service/business-internet-providers', '/service/home-internet-providers', '/service/iptv', '/service/free-wifi-hotspots', '/service/managed-network', '/service/cloud-cam', '/service/VoIP-intercom', '/service/cdn-services', '/pricing/custom-plan', '/pricing/ott', '/pricing/entertainment-combo', '/pricing/business-internet', '/pricing/home-internet', '/pricing/broadband', '/cities/:types', '/cities', "/blog/essential-features-in-broadband-apps-consumers-guide", "/blog/how-to-choose-best-internet-service-provider","/disclaimer"]
// const unauth = ["/thanks","*","/pricing/:handle","/search","/sitemap","/", "/login", "/register",
//   "/contact-us", "/about-us","/",
//   "/plans", "/vision", "/invest",
//   "/pricing", "/blog", "/career",
//   "/franchisee", "/privacy-policy", "/refund-policy", "/faqs",
//   "/terms-conditions", "/blog/essential-features-in-broadband-apps-consumers-guide", "/blog/how-to-choose-best-internet-service-provider", "/blog/free-wifi-hotspots-malls-business-parks", "/customer",
//   '/service/internet-leased-line',
//   '/service/business-internet-providers',
//   '/service/home-internet-providers', '/service/iptv', '/service/free-wifi-hotspots', '/service/managed-network', '/service/cloud-cam', '/service/VoIP-intercom',
//   '/service/cdn-services', '/pricing/broadband', '/pricing/custom-plan', '/pricing/ott', '/pricing/entertainment-combo', '/pricing/business-internet', '/pricing/home-internet', '/cities', '/cities/:types','/disclaimer'
// ]


function App() {
  // const location =useLocation()

  const [login, setLogin] = useState(false);
  const [userData, setUserData] = useState(null);
  const [token, setToken] = useState("")
  const id = JSON.parse(localStorage.getItem("user"))?._id || ""
  const isActive = JSON.parse(localStorage.getItem("user"))?.isActivecustomer

  const data = {
    login,
    setLogin,
    userData,
    setUserData,
  };

  useEffect(() => {
    setToken(id)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const sessionData = sessionStorage.getItem("userInfo");
    if (sessionData) {
      setLogin(true);
      setUserData(JSON.parse(sessionData));
    } else {
      setLogin(false);
      setUserData(null);
    }
  }, [setLogin, setUserData]);
  const [path, setPath] = useState("")
  useEffect(() => {
    setPath(window.location.pathname)
  }, [window.location.pathname])

  return (

    <AccountContext.Provider value={data}>
      <BrowserRouter>
        <>
          <Routes>
            {/* <Route element={<PrivateRoutes />}>
              <Route path="/customer" element={<CustomerForm />} />
            </Route> */}
            <Route path="/" exact element={<Home />} />
            <Route path="/contact-us" exact element={<Contact />} />
            <Route path="/about-us" exact element={<About />} />
            <Route path="/pricing" exact element={<Plans />} />
            <Route path="/vision" exact element={<Vision />} />
            <Route path="/invest" exact element={<Invest />} />
            <Route path="/pricing/broadband" exact element={<Broadband />} />
            <Route path="/blogs" exact element={<Blog />} />
            <Route path="/career" exact element={<Career />} />
            <Route path="/franchisee" exact element={<Franchisee />} />
            <Route path="/privacy-policy" exact element={<PrivacyPolicy />} />
            <Route path="/refund-policy" exact element={<RefundPolicy />} />
            <Route path="/disclaimer" exact element={<Disclaimer />} />
            <Route path="/service/:type" exact element={<ServicesDetails />} />
            <Route path="/faqs" exact element={<FAQ />} />
            <Route path="/get-myinternet" exact element={<GetMyInternet />} />
            <Route path="/terms-conditions" exact element={<TermsConditions />} />
            <Route path="/blogs/:handle" exact element={<BlogDetails />} />
            <Route path="/cities" element={<City />} />
            <Route path='/cities/:types' element={<CityDetails />} />
            <Route path='/pricing/:handle' exact element={<Pricing />} />
            <Route path='/search' exact element={<Searchs />} />
            <Route path='/sitemap' exact element={<SiteMap />} />
            <Route path='/thanks' exact element={<Thanks />} />
            <Route path='*' exact element={<NotFoundPage/>} />

             {redirectiondata?.map((data) => {
              return (
                <Route path={data?.pasturl} element={<Navigate to={data?.currenturl} replace />} />
              )
            }
            )}
          </Routes>

          {/* {id ?
            <Routes>
              <Route path="/login" element={<Navigate to="/customer" replace />} />
              <Route path="/register" element={<Navigate to="/customer" replace />} />
              {(window.location.pathname && !auth.includes(window.location.pathname)) && <Route path="*" element={<NotFoundPage />} />}
            </Routes>
            :
            <Routes>
              <Route path="/login" exact element={<Login />} />
              <Route path="/register" exact element={<Register />} />
              <Route path="/customer" element={<Navigate to="/login" replace />} />
              <Route path="/customer" element={<Navigate to="/login" replace />} />
              {(!unauth.includes(path) && !unauth.includes(window.location.pathname)) ? <Route path="*" element={<NotFoundPage />} /> : ""}
            </Routes>
          } */}

        </>
      </BrowserRouter>
    </AccountContext.Provider>

  );
}

export default App;

const redirectiondata = [
  {
    pasturl: "/post/big-data-101-an-introduction-to-data-query-engines",
    currenturl: "/blogs"
  },
  {
    pasturl: "/post/now-is-the-time-for-revenue-intelligence",
    currenturl: "/blogs"
  },
  {
    pasturl: "/post/the-online-database-migration-guide",
    currenturl: "/blogs"
  },
  {
    pasturl: "/copy-of-terms-conditions",
    currenturl: "/terms-conditions"
  },
  {
    pasturl: "/copy-of-privacy-policy",
    currenturl: "/privacy-policy"
  },
  {
    pasturl: "/get-started",
    currenturl: "/contact-us"
  },
  {
    pasturl: "/invest",
    currenturl: "/pricing"
  },
  {
    pasturl: "/plans",
    currenturl: "/pricing/custom-plan"
  },
  {
    pasturl: "/services",
    currenturl: "/"
  },
  {
    pasturl: "/aboutus",
    currenturl: "/about-us"
  }
]
