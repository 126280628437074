import React from 'react'
import SEO from '../components/shared/seo'
import InnerPageBanner from '../components/shared/innerPageBanner'
import ImageIcons from '../components/imageComponent/ImageIcons';
import Cities from '../components/cities/cities'
import Search from '../components/cities/search'
import Layout from "../components/shared/layout";

const City = () => {
  return (
    <div>
       <SEO
        title="Top Cities for Internet Services | My Internet"
        description="Find the best cities for reliable internet services. Find top-rated ISPs and connectivity solutions for your location at My Internet Services."
      /> 
        <Layout>
      <InnerPageBanner title='Cities' innerPageBanner={ImageIcons.Cities} />
      <Search/>
      <Cities/>
      </Layout>

    </div>
  )
}

export default City