import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import { IoMdMenu } from "react-icons/io";
import Collapse from '@mui/material/Collapse';
import { useNavigate } from 'react-router';
import { IoIosArrowUp } from "react-icons/io";
import { IoIosArrowDown } from "react-icons/io";
import ImageIcons from '../imageComponent/ImageIcons';
import { Link, useLocation } from "react-router-dom";

const DrawerMenu = ({ navLinks }) => {
    console.log("navLinks", navLinks)
    const navigate = useNavigate();
    const [showLogoutModal, setShowLogoutModal] = useState(false); // State for logout confirmation modal
    const [openSubMenu, setOpenSubMenu] = useState(null);
    const [state, setState] = useState({ left: false });

    const pathname = useLocation().pathname;

    const toggleDrawer = (anchor, open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }

        setState({ ...state, [anchor]: open });
    };

    const handleToggleSubMenu = (index) => {
        setOpenSubMenu((prev) => (prev === index ? null : index));
    };

    const storedUser = JSON.parse(localStorage.getItem('user'));

    console.log("storedUser", storedUser)
    const id = storedUser?._id
    // const isActive = storedUser?.isActivecustomer

    const handleSub = (event) => {
        setShowLogoutModal(false);
        event.preventDefault();

        // Remove item from localStorage
        // localStorage.removeItem('user');
        localStorage.clear()
        navigate('/login')
        window.location.reload()

        // Optionally, you can perform other actions here after removing the item
    }
    const handle = () => {
        setShowLogoutModal(true);
    }

    const list = (anchor) => (
        <Box
            sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 260 }}
            role="presentation"
            onClick={toggleDrawer(anchor, true)}
            onKeyDown={toggleDrawer(anchor, false)}
        >
            <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "space-between", height: "100vh" }}>
                <List className='!py-0'>
                    <div className='px-[20px] py-[10px] bg-[#000]'>
                        <Link to='/' onClick={() => {
                        }} >
                            <img className='h-[73px]' src={ImageIcons?.logo} alt='Logo' />
                        </Link>
                    </div>
                    {navLinks.map((item, index) => (
                        <div className='group' key={index}>
                            <ListItem key={index} disablePadding className={`border-solid border-b border-[#ffe3ce] group-last:border-none`}>
                                <ListItemButton onClick={() => navigate(item.handle)} sx={{ padding: "8px 20px" }}>
                                    <ListItemText className={` ${pathname === item.handle && "text-[#f48533]"}`} primary={item.title} />
                                </ListItemButton>
                                {item.subLinks && (
                                    <ListItemButton className={`!flex !justify-end !absolute right-0 !bg-[#f48533] !text-white h-full text-[22px] `} onClick={() => handleToggleSubMenu(index)}>{openSubMenu === index ? <IoIosArrowUp /> : <IoIosArrowDown />}</ListItemButton>
                                )}
                            </ListItem>
                            {item.subLinks && (
                                <Collapse in={openSubMenu === index} timeout="auto" unmountOnExit>
                                    <List component="div" disablePadding>
                                        {item.subLinks.map((subItem, subIndex) => (
                                            <ListItem key={subIndex} disablePadding className={`bg-[#000] text-white border-solid border-b border-[#3f3f3f] group-last:border-none `}>
                                                {subItem?.type &&
                                                    <ListItemButton
                                                        onClick={() => {
                                                            window.location.pathname = `service/${subItem?.type}`
                                                            toggleDrawer('left', false)
                                                        }}
                                                        sx={{ padding: "10px 20px" }}
                                                    >
                                                        <ListItemText primary={subItem.title} />
                                                    </ListItemButton>
                                                }
                                                {subItem?.handle &&
                                                    <ListItemButton
                                                        onClick={() => {
                                                            window.location.pathname = `pricing/${subItem?.handle}`
                                                            toggleDrawer('left', false)
                                                        }}
                                                        sx={{ padding: "10px 20px" }}
                                                    >
                                                        <ListItemText primary={subItem.title} />
                                                    </ListItemButton>
                                                }
                                            </ListItem>
                                        ))}
                                    </List>
                                </Collapse>
                            )}
                        </div>
                    ))}

                    <li className="w-full">
                        <Link to='/contact-us' className='border-solid border-b border-[#ffe3ce] w-full inline-block h-[48px]  text-[16px] hover:bg-[#f5f5f5] px-[20px] py-[10px]'>Get New Conncetion</Link>
                    </li>
                    <li className="w-full">
                        <Link to='/contact-us' className='border-solid border-b border-[#ffe3ce] w-full inline-block h-[48px]  text-[16px] hover:bg-[#f5f5f5] px-[20px] py-[10px]'>Pay Bill / Recharge</Link>
                    </li>

                    {!id ?
                        <>
                            {/* <li className="w-full">
                                <Link to='/register' className='border-solid border-b border-[#ffe3ce] w-full inline-block h-[48px]  text-[16px] hover:bg-[#f5f5f5] px-[20px] py-[10px]'>Register</Link>
                            </li> */}
                            <li className="w-full">
                                <Link to='https://myaccount.myinternetzone.com/' className='border-solid border-b border-[#ffe3ce] w-full inline-block h-[48px]  text-[16px] hover:bg-[#f5f5f5] px-[20px] py-[10px]'>Login</Link>
                            </li>
                        </>
                        :
                        null
                    }

                    {id ? (
                        <>
                            <li className="w-full">
                                <Link to='/customer' className='border-solid border-b border-[#ffe3ce] w-full inline-block h-[48px]  text-[16px] hover:bg-[#f5f5f5] px-[20px] py-[10px]'>Customer Portal</Link>
                            </li>
                            <li>
                                <button type='button' onClick={() => handle()} className='border-solid border-b text-left border-[#ffe3ce] w-full inline-block h-[48px]  text-[16px] hover:bg-[#f5f5f5] px-[20px] py-[10px]'>Logout</button>
                            </li>
                        </>
                    ) : null}



                    {/* 
                    <ListItem className='mt-[8px]'>
                        <Link to='#' className='text-[#fff] px-[20px] py-[10px] w-full text-center rounded-[10px] bg-[#f48533]'>
                            <ListItemText primary="Get New Conncetion" />
                        </Link>
                    </ListItem>
                    <ListItem>
                        <Link to='#' className='text-[#fff] px-[20px] py-[10px] w-full text-center rounded-[10px] bg-[#f48533]'>
                            <ListItemText primary="Pay Bill / Recharge" />
                        </Link>
                    </ListItem> */}
                </List>
                {/* <List>
                    <ListItem>
                        <Link to='/login' className='!duration-500 !bg-[#000] hover:!bg-[#F48533] !text-white w-full flex items-center !text-center !text-[18px] !font-bold h-[50px]'>
                            <ListItemText primary="Login" />
                        </Link>
                    </ListItem>
                </List> */}

                {showLogoutModal && (
                    <div className="fixed top-0 left-0 right-0 bottom-0 flex justify-center items-center bg-black bg-opacity-50 z-50">
                        <div className="bg-white p-6 rounded-lg shadow-lg">
                            <p className="mb-4">Are you sure you want to logout?</p>
                            <div className="flex justify-center">
                                <button className="bg-red-500 text-white px-4 py-2 mr-4 rounded-md" onClick={(e) => handleSub(e)}>Yes</button>
                                <button className="bg-gray-300 text-gray-800 px-4 py-2 rounded-md" onClick={() => setShowLogoutModal(false)}>No</button>
                            </div>
                        </div>
                    </div>
                )}
            </Box>
        </Box>
    );

    return (
        <>
            <div>
                {['left'].map((anchor) => (
                    <React.Fragment key={anchor}>
                        <Button className='!p-0 !min-w-fit' onClick={toggleDrawer(anchor, true)}><IoMdMenu className='text-[36px] text-white' /></Button>
                        <Drawer
                            anchor={anchor}
                            open={state[anchor]}
                            onClose={toggleDrawer(anchor, false)}
                        >
                            {list(anchor)}
                        </Drawer>
                    </React.Fragment>
                ))}
            </div>
        </>
    );
};

export default DrawerMenu;