import React from 'react';
import Layout from '../components/shared/layout';
import InnerPageBanner from '../components/shared/innerPageBanner';
import ImageIcons from '../components/imageComponent/ImageIcons';
import { FaCheckSquare } from "react-icons/fa";
import { IoWifiSharp } from "react-icons/io5";
import { Link } from 'react-router-dom';
import { windowScroll } from '../components/utils/windowScroll';
import SEO from '../components/shared/seo';

const Plans = () => {
  return (
    <>
    <SEO title='Affordable Internet Plans | My Internet Pricing' description='Get affordable internet plans With MyInternetZone. Check pricing, compare options, and find the best deal for your home internet needs.' keywords='"internet pricing, broadband plans, My Internet Zone prices, internet plans, affordable internet packages, my internet plans list"'/>

    <Layout>
      <InnerPageBanner title='Pricing' innerPageBanner={ImageIcons.contactBg} />
      <section>
        <div className='container'>
          <div className='max-w-[800px] md:mb-[50px] mb-[40px] text-center mx-auto'>
            <div className='text-[#f48533] uppercase mb-[5px] '>Our Pricing Tables</div>
            <h2>No Charges For <span className='text-[#f48533]'>Cable TV</span> With Internet</h2>
          </div>

          {PlansData.map((item, i) => (
            <div key={i} className='flex items-center max-md:flex-wrap gap-[20px] max-lg:gap-[15px] justify-between shadow-[0px_2px_15px_0px_rgba(0,0,0,0.1)] bg-[#fff] mb-[20px] p-[20px] max-lg:p-[15px] max-md:p-[20px]'>
              <div className='w-[200px] max-lg:w-[180px] max-sm:w-[120px]'>
                <svg className="w-full h-full" viewBox="0 0 100 100">
                  <circle
                    className="text-gray-200 stroke-current"
                    strokeWidth="5"
                    cx="50"
                    cy="50"
                    r="47"
                    fill="transparent"
                  ></circle>
                  <circle
                    className="text-[#f48533]  progress-ring__circle stroke-current"
                    strokeWidth="5"
                    strokeLinecap="round"
                    cx="50"
                    cy="50"
                    r="47"
                    fill="transparent"
                    strokeDashoffset={`calc(300 - (300 * ${item.speed}) / 300)`}
                  ></circle>
                  <text x="50" y="50" fontSize="18" textAnchor="middle" alignmentBaseline="middle">
                    <tspan x="50" dy="0" fontWeight={600} fill="#30353a">{item.speed}</tspan>
                    <tspan x="50" dy="15" fontSize="12" fill="#818487">Mbps</tspan>
                  </text>
                </svg>
              </div>

              <div className='w-[20%] max-md:w-[calc(100%_-_200px)] max-sm:w-[calc(100%_-_135px)]'>
                <h3 className='text-[#30353a] mb-[8px]'>{item.packageName}</h3 >
                <div className='text-[#818487]'>{item.data} <IoWifiSharp className='text-[#f48533] inline-block text-[22px] ml-[15px]' /></div>
              </div>

              <div className='w-[40%] max-md:w-[100%] max-md:mt-[10px]'>
                <ul>
                  <li className='mb-[8px] text-[#14212bb3]'><FaCheckSquare className='inline-block text-[#f48533] text-[20px] mr-[2px]' /> <span className='font-medium'>IPTV/Cable TV Combo Package:</span> {item.IPTV}</li>
                  <li className='mb-[8px] text-[#14212bb3]'><FaCheckSquare className='inline-block text-[#f48533] text-[20px] mr-[2px]' /> <span className='font-medium'>SD Channels:</span> {item.SDChannels}</li>
                  <li className='mb-[8px] text-[#14212bb3]'><FaCheckSquare className='inline-block text-[#f48533] text-[20px] mr-[2px]' /> <span className='font-medium'>HD Channels:</span> {item.hdChannels}</li>
                  <li className='text-[#14212bb3]'><FaCheckSquare className='inline-block text-[#f48533] text-[20px] mr-[2px]' /> <span className='font-medium'>OTT Benifits:</span> {item.OTTBenifits}</li>
                </ul>
              </div>

              <div className='relative min-w-[280px] max-xl:min-w-[250px] max-md:min-w-[100%] max-lg:min-w-[225px] max-md:mt-[20px] bg-[#fff3ea] text-center py-[25px] px-[10px]'>
                <div className='mb-0'>RS.<span className='lg:text-[54px] md:text-[48px] sm:text-[38px] text-[32px] font-bold	text-[#30353a]'>{item.monthPrice}</span>/ 1 month</div>
                  <div className='mb-[12px] text-[#e60023] font-medium'>* Taxes Extra</div>
                 <Link to='/contact-us' onClick={windowScroll} className="px-[40px] text-[17px] py-[13px] text-[#fff] bg-[#f48533] rounded-[10px] hover:bg-[#000] ease inline-block">
                Get Started</Link>
              </div>
            </div>
          ))}
        </div>
      </section>

    </Layout>
    </>
  )
}

const PlansData = [
  {
    // title: 0,
    monthPrice: "499",
    quarterlyPrice: "1497",
    packageName: "Combo Basic",
    speed: "40",
    data: "Unlimited",
    IPTV: "Silver Suggestive",
    SDChannels: "185",
    hdChannels: "18",
    OTTBenifits: "NIL",
    monthlyGST: 589,
  },
  {
    // title: 1,
    monthPrice: "649",
    quarterlyPrice: "1947",
    packageName: "Combo Value",
    speed: "60",
    data: "Unlimited",
    IPTV: "Gold Suggestive",
    SDChannels: "248",
    hdChannels: "17",
    OTTBenifits: "NIL",
    monthlyGST: 766,
  },
  {
    // title: 2,
    monthPrice: "849",
    quarterlyPrice: "2547",
    packageName: "Combo Gold",
    speed: "100",
    data: "Unlimited",
    IPTV: "Gold Suggestive HD",
    SDChannels: "238",
    hdChannels: "39",
    OTTBenifits: "NIL",
    monthlyGST: 1002,
  },
  {
    // title: 3,
    monthPrice: "999",
    quarterlyPrice: "2997",
    packageName: "Combo Premium",
    speed: "150",
    data: "Unlimited",
    IPTV: "Gold Suggestive HD",
    SDChannels: "238",
    hdChannels: "39",
    OTTBenifits: "On Quarterly Subscription",
    monthlyGST: 1179,
  },
  {
    // title: 4,
    monthPrice: "1199",
    quarterlyPrice: "3597",
    packageName: "Combo Ultra",
    speed: "200",
    data: "Unlimited",
    IPTV: "Platinum Suggestive HD",
    SDChannels: "238",
    hdChannels: "56",
    OTTBenifits: "On Quarterly Subscription",
    monthlyGST: 1415,
  },
  {
    // title: 5,
    monthPrice: "1499",
    quarterlyPrice: "4497",
    packageName: "Combo Blaze",
    speed: "300",
    data: "Unlimited",
    IPTV: "Platinum Suggestive HD",
    SDChannels: "238",
    hdChannels: "56",
    OTTBenifits: "On Quarterly Subscription",
    monthlyGST: 1769,
  }
]

export default Plans