import React from 'react'
import ImageIcons from '../imageComponent/ImageIcons';
import Button from '../shared/button';
// import { FaStackOverflow } from "react-icons/fa6";
// import { MdOutlineWifiTethering } from "react-icons/md";
// import { TbFloatCenter } from "react-icons/tb";
// import { IoMdCall } from "react-icons/io";

const DreamInternet = () => {

  return (
    <section className='relative overflow-hidden mt-[25px]'>
      <div className='absolute w-[250px] right-0 bottom-[20px] animate-[animate-bounceTop_5s_infinite_ease-in-out_alternate] max-lg:opacity-30 opacity-40'>
        <img className='object-cover' src={ImageIcons.inneraboutShape} alt='Shape' />
      </div>
      <div className='container '>
        <div className='flex lg:flex-nowrap flex-wrap items-center'>
          <div className='md:min-h-[500px] sm:min-h-[400px] md:ml-[25px] ml-[15px] min-h-[330px] relative lg:w-[50%] w-full content-[""] after:absolute md:after:top-[-25px] after:top-[-15px] md:after:left-[-25px] after:left-[-15px] after:w-[calc(100%_-_80px)] after:z-[-1] after:animate-[auto-fill_5s_infinite_ease-in-out_alternate] after:rounded-[20px] after:bg-[#f38432] md:after:h-[calc(100%_+_50px)] after:h-[calc(100%_+_30px)]'>
            <img className='object-cover h-full w-full absolute rounded-[20px]' src={ImageIcons.myinternetBanner} alt='Myinternet' />
          </div>

          <div className='lg:w-[50%] w-full xl:pl-[50px] lg:pl-[40px] relative max-lg:pt-[50px]' >
            <div className='relative z-10 lg:max-w-[700px] max-w-full'>
              <h1 className='lg:text-[40px] md:text-[34px] text-[30px] text-[#f38432]'>Internet Broadband Service Providers - My Internet</h1>

              <p>MyInternet services providers are a leading ISP in India. We offer advanced and future-proof internet services for both residential and enterprise purposes.As we aim to create a smooth and safely connected world, we are dedicated to making internet services accessible in every corner of India.  Our commitment to provide quality services has always encouraged us to deliver customer-centric services with our cutting-edge infrastructure. We guarantee transforming your experience of using the internet. Join us today and enjoy our fast and reliable internet services.
              </p>

              <Button hrefLink='/about-us' title='Learn More' />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default DreamInternet;