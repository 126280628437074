import React from 'react'
import ImageIcons from '../imageComponent/ImageIcons';
// import Button from '../shared/button';

const Message = () => {
  return (
    <section className='lg:my-[25px] my-[15px] relative pb-[0]'>
      <div className='absolute w-[220px] left-[20px] bottom-[20px] animate-[animate-bounceTop_5s_infinite_ease-in-out_alternate] max-lg:opacity-70'>
        <img className='object-cover rotate-180' src={ImageIcons.aboutShape} alt='Shape' />
      </div>
      <div className='container '>
        <div className='flex lg:flex-nowrap flex-wrap lg:gap-[50px] gap-[40px] items-center'>
          <div className='lg:w-[50%] max-lg:order-2 w-full lg:py-[40px] py-0 relative ' >
            <div className='relative z-10'>
              {/* <h2 className='font-bold'>Our<span className='text-[#f38432]'> Story</span></h2> */}
              <p className='text-[18px]'>My Internet, which was launched in 2016 all over Punjab, possesses an all Punjab Service Area Pan India, Grade A ISP licence granted by the Department of Telecommunications (DOT), Govt. of India. As we believe in staying connected in today’s digital world, we have our services in more than 18 states of India with 57000+ customers. At my Internet, we are devoted to providing our users with blazing-fast, reliable and affordable broadband internet services, so that you can enjoy buffer free streaming, uninterrupted gaming, uploading, downloading and much more.  Our focus on making high-quality internet accessible to everyone has helped us establish ourselves at the forefront of the marketplace, as since our launch, we have never failed in delivering outstanding services and support.</p>
              <p className='text-[18px]'>My Internet offers its customers with a wide spectrum of plans which have especially been designed to meet your unique needs. Our affordable plans never prove to be heavy on your pocket. Even users are allowed to get the plans customised to their unique needs, as we just want to keep you connected. Moreover, the satisfaction of the customer is our top priority, thereby we believe in transparency in all our interactions.</p>
              <p className='text-[18px] mb-0'>Our journey started with just a simple idea of providing outstanding and exceptional internet services to our customers. We understand the need of better connectivity and reliable internet broadband services and for this, we launched My Internet services. Our aim of continuous innovation and dedication helped us to grow and become a reputable and trusted name in the market.</p>
              {/* <Button hrefLink='#' title='Learn More' /> */}
            </div>
          </div>
          <div className='md:min-h-[600px] max-lg:order-1 sm:min-h-[400px] md:mr-[25px] mr-[15px] min-h-[300px] relative lg:w-[50%] w-full after:content-[""] after:absolute after:rounded-[20px] md:after:top-[-25px] after:top-[-15px] md:after:right-[-25px] after:right-[-15px] after:w-[calc(100%_-_50%)] after:z-[-1] after: after:bg-[#f38432] after:h-full'>
            <img className='object-cover h-full w-full absolute rounded-[20px]' src={ImageIcons.myinternetBanner} alt='MyInternet' />
          </div>
        </div>
      </div>
    </section>
  )
}

export default Message