import React from "react";
import ImageIcons from '../imageComponent/ImageIcons';
import Button from '../shared/button';
import Slider from "react-slick";

const Hero = () => {
  let settings = {
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    dots: true,
    fade: true,
    speed: 1000,
    adaptiveHeight: true,
  };

  return (
    <section className="banner bg-[#fff3ea] overflow-hidden 2xl:pt-[60px] pt-[50px] pb-0">
      <div className='container'>

        <Slider {...settings}>
          {sliderData.map((item, i) => (
            <div key={i} className='relative'>
              <div className='flex lg:flex-nowrap flex-wrap lg:gap-[30px] gap-[50px] items-center'>
                <div className='2xl:w-[42%] lg:w-[45%] w-[100%] lg:text-left text-center'>
                  <img className="2xl:h-[40px] md:h-[35px] h-[30px] max-lg:m-auto max-2xl:mb-[6px]" src={item.entertainmentIcon} alt="Entertainment" />
                  <h2 className="2xl:text-[48px] lg:text-[41px] md:text-[48px] text-[32px]">{item.title}</h2>
                  <p className='2xl:mb-[40px] mb-[20px]'>{item.text}</p>
                  <Button hrefLink={item.handle} title='Learn More' />
                </div>

                <div className='relative 2xl:w-[58%] lg:w-[55%] w-[100%]'>
                  <img className='relative z-10 m-auto md:mt-[20px] sm:h-[550px] h-[400px]' src={item.heroImg} alt={item.heroAlt} />
                  <img className='bannerImg absolute top-0 left-0 right-0 m-auto sm:w-[80%] w-[100%]' src={ImageIcons.globShape} alt='Glob Shape' />
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </section>
  )
}

const sliderData = [
  {
    title: "Superfast Home Broadband Plans Starting at ₹399/mo*",
    text: "Get the best broadband connection for your home for only ₹399 per month. Enjoy reliable, high-speed internet for all your online needs.",
    handle: '/pricing/broadband',
    heroImg: ImageIcons.superfastHomeBroadbandPlans,
    entertainmentIcon: ImageIcons.intertainment,
  },
  {
    title: "Empower Your Business with Customised Internet Plans Starting @ ₹499/mo*",
    text: "Boost your business with reliable connectivity and seamless operations. Our customised internet plans start at just ₹499 per month.",
    handle: '/pricing/custom-plan',
    heroImg: ImageIcons.empowerYourBusiness,
    heroAlt: "Empower Your Business",
    entertainmentIcon: ImageIcons.entertainment,
  },
  {
    title: "All-in-One Internet + IPTV + OTT Services for Just ₹399/Month",
    text: "Unlock ultimate entertainment with our combined internet, IPTV, and OTT services for only ₹399 per month. Enjoy seamless streaming and connectivity in one affordable package.",
    handle: '/pricing/home-internet',
    heroImg: ImageIcons.netIptvOtt,
    heroAlt: "Internet + IPTV + OTT",
    entertainmentIcon: ImageIcons.intertainment,
  },
  {
    title: "Free WiFi Hotspots Connect Anytime, Anywhere",
    text: "Stay connected effortlessly with our free WiFi hotspot services available in shopping malls and residential societies. Enjoy seamless internet access anytime, anywhere.",
    handle: '/service/free-wifi-hotspots',
    heroImg: ImageIcons.freeHotspot,
    entertainmentIcon: ImageIcons.intertainment,
  },
]
export default Hero