import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { IoCheckmarkDoneSharp } from "react-icons/io5";
import ImageIcons from '../imageComponent/ImageIcons';

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const Plans = () => {
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <section className='plans relative bg-cover bg-no-repeat bg-center after:content-[""] after:absolute after:w-full after:h-full after:top-0 after:inset-x-0 after:bg-[#f48533] after:opacity-5 ' style={{ backgroundImage: `url(${ImageIcons.plansBg})` }}>
      <div className='container relative z-20'>
        <div className='max-w-[1000px]'>
          <h2 className='max-w-[500px] md:mb-[50px] mb-[40px]'>Choose <span className='text-[#f48533]'>Ultra-Fast</span> Internet Package For Usage</h2>

          <Box sx={{ width: '100%' }}>
            <Box>
              <Tabs
                sx={{ marginBottom: '40px' }}
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
                variant="scrollable"
              >
                {tabHeader.map((item, i) => (
                  <Tab className='relative before:content=[""] before:absolute before:top-[25px] before:left-[-15px] before:border-b-[#336ef9] before:border-[15px] before:border-transparent' label={item} {...a11yProps(i)} sx={{ backgroundColor: "#e9e9f1", color: "#000 !important", marginRight: "15px", ...value === i && { backgroundColor: "#336ef9", color: "#fff !important" } }} />
                ))}
              </Tabs>
            </Box>
            {panelData.map((data, i) => (
              <CustomTabPanel value={value} index={data.index} >
                <div>
                  <div className='flex items-center md:mb-[40px] mb-[30px]'>
                    <div className='border border-[#336ef9] sm:p-[28px] p-[15px] bg-[#fff] sm:mr-[40px] mr-[15px] rounded-[5px] inline-block'>RS.<span className='md:text-[54px] sm:text-[38px] text-[25px] font-bold	text-[#336ef9]'>{data.monthPrice}</span>/ 1 month</div>
                    <div className='inline-block text-[#f48533]'>RS.<span className='md:text-[54px] sm:text-[38px] text-[25px] font-bold'>{data.quarterlyPrice}</span>/ Quarterly</div>
                  </div>
                  <div class="mb-[12px] text-[#e60023] font-medium">* Taxes Extra</div>
                  <ul>
                    <li className='mb-[15px] sm:text-[17px] text-[16px] text-[#14212bb3]'><IoCheckmarkDoneSharp className='inline-block text-[#f48533] mr-[5px]' /> <span className='font-medium'>Speed:</span> {data.speed}</li>
                    <li className='mb-[15px] sm:text-[17px] text-[16px] text-[#14212bb3]'><IoCheckmarkDoneSharp className='inline-block text-[#f48533] mr-[5px]' /> <span className='font-medium'>Data:</span> {data.data} </li>
                    <li className='mb-[15px] sm:text-[17px] text-[16px] text-[#14212bb3]'><IoCheckmarkDoneSharp className='inline-block text-[#f48533] mr-[5px]' /> <span className='font-medium'>IPTV/Cable TV Combo Package:</span> {data.IPTV}</li>
                    <li className='mb-[15px] sm:text-[17px] text-[16px] text-[#14212bb3]'><IoCheckmarkDoneSharp className='inline-block text-[#f48533] mr-[5px]' /> <span className='font-medium'>SD Channels:</span> {data.SDChannels}</li>
                    <li className='mb-[15px] sm:text-[17px] text-[16px] text-[#14212bb3]'><IoCheckmarkDoneSharp className='inline-block text-[#f48533] mr-[5px]' /> <span className='font-medium'>HD Channels:</span> {data.hdChannels}</li>
                    <li className='mb-[15px] sm:text-[17px] text-[16px] text-[#14212bb3]'><IoCheckmarkDoneSharp className='inline-block text-[#f48533] mr-[5px]' /> <span className='font-medium'>OTT Benifits:</span> {data.OTTBenifits}</li>
                  </ul>
                </div>
              </CustomTabPanel>
            ))}

          </Box>
        </div>
      </div>
    </section>
  )
}

export default Plans

const tabHeader = ["Combo Basic +", "Combo Value +", "Combo Gold +", "Combo Premium +", "Combo Ultra +", "Combo Blaze +"]

const panelData = [
  {
    index: 0,
    monthPrice: "499",
    quarterlyPrice: "1497",
    speed: "40Mbps",
    data: "Unlimited",
    IPTV: "Silver Suggestive",
    SDChannels: "185",
    hdChannels: "18",
    OTTBenifits: "NIL",
    monthlyGST: 589,
  },
  {
    index: 1,
    monthPrice: "649",
    quarterlyPrice: "1947",
    speed: "60Mbps",
    data: "Unlimited",
    IPTV: "Gold Suggestive",
    SDChannels: "248",
    hdChannels: "17",
    OTTBenifits: "NIL",
    monthlyGST: 766,
  },
  {
    index: 2,
    monthPrice: "849",
    quarterlyPrice: "2547",
    speed: "100Mbps",
    data: "Unlimited",
    IPTV: "Gold Suggestive HD",
    SDChannels: "238",
    hdChannels: "39",
    OTTBenifits: "NIL",
    monthlyGST: 1002,
  },
  {
    index: 3,
    monthPrice: "999",
    quarterlyPrice: "2997",
    speed: "150Mbps",
    data: "Unlimited",
    IPTV: "Gold Suggestive HD",
    SDChannels: "238",
    hdChannels: "39",
    OTTBenifits: "On Quarterly Subscription",
    monthlyGST: 1179,
  },
  {
    index: 4,
    monthPrice: "1199",
    quarterlyPrice: "3597",
    speed: "200Mbps",
    data: "Unlimited",
    IPTV: "Platinum Suggestive HD",
    SDChannels: "238",
    hdChannels: "56",
    OTTBenifits: "On Quarterly Subscription",
    monthlyGST: 1415,
  },
  {
    index: 5,
    monthPrice: "1499",
    quarterlyPrice: "4497",
    speed: "300Mbps",
    data: "Unlimited",
    IPTV: "Platinum Suggestive HD",
    SDChannels: "238",
    hdChannels: "56",
    OTTBenifits: "On Quarterly Subscription",
    monthlyGST: 1769,
  }
]