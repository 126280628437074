import React, { useState } from "react";
import ImageIcons from "../imageComponent/ImageIcons";
import Slider from "react-slick";
import { PiQuotesFill } from "react-icons/pi";

const Testimonial = () => {
    const [nav1, setNav1] = useState();
    const [nav2, setNav2] = useState();

    return (
        <>
            <section className="testimonial relative bg-no-repeat" style={{ backgroundImage: `url(${ImageIcons.testimonialBg})` }}>
                <div className="container">
                    <div className="flex md:flex-nowrap items-center justify-between flex-wrap gap-[20px]">
                        <div className="relative md:w-[60%] w-[100%]">
                            <h2 className="xl:mb-[50px] md:mb-[40px] mb-[30px]">What<span className="text-[#f48533]"> People Say</span> About Us</h2>
 
                            <PiQuotesFill className="absolute xl:right-[100px] lg:right-[50px] right-[20px] lg:top-[-100px] md:top-[-50px] top-[75%] opacity-20 lg:text-[120px] text-[80px] text-[#f48533]" />

                            <Slider asNavFor={nav2} ref={(slider1) => setNav1(slider1)} arrows={false}
                                fade={false} className="settings">
                                {servicesData.map((item, i) => (
                                    <div key={i}>
                                        <div className="xl:max-w-[650px] lg:max-w-[570px] md:max-w-[410px] max-w-full">
                                            <p className='lg:text-[28px] md:text-[20px] text-[18px] italic'>{item.text}</p>
                                            <div className="lg:mt-[50px] mt-[40px] flex justify-center group">
                                                <span className="flex justify-center items-center h-[70px] w-[70px] duration-500 relative before:absolute before:left-[-5px] before:w-full before:h-full before:bg-[#f38432] before:content-[''] before:bottom-[-5px] before:z-[-1]">
                                                    <img src={item.clientIcon} alt="client" />
                                                </span>
                                                <div className="md:pl-[30px] pl-[20px] text-left sm:w-[calc(100%_-_55px)] w-[calc(100%_-_50px)]">
                                                    <h5 className="mb-[5px] md:text-[20px] text-[18px]">{item.title}</h5>
                                                    <p className="mb-0 font-normal">{item.designation}</p>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                ))}
                            </Slider>
                        </div>
                        <div className="testimonialBtn md:w-[40%] w-[100%]">
                            <img className="max-md:hidden block rounded-[10px] ml-auto" src={ImageIcons.testimonialBanner} alt="Testimonial Banner" />
                            <div className="lg:max-w-[400px] max-w-[230px] lg:mt-[-152px] md:mt-[-84px] mt-0 bg-[#fff] relative lg:p-[20px]  md:p-[10px] lg:!pb-[13px]  md:!pb-[3px] !pb-0 p-0 lg:ml-[-90px] md:ml-[-40px] ml-0 max-md:m-auto">
                                <Slider asNavFor={nav1}
                                    ref={(slider2) => setNav2(slider2)}
                                    slidesToShow={3}
                                    swipeToSlide={true}
                                    arrows={false}
                                    fade={false}
                                    autoplay={true}
                                    autoplaySpeed={5000}
                                    focusOnSelect={true} className="sliderNav">
                                    {servicesData.map((item, i) => (
                                        <div key={i}>
                                            <div className="cursor-pointer">
                                                <img src={item.clientIcon} alt={item.clientAlt} />
                                            </div>
                                        </div>
                                    ))}
                                </Slider>
                            </div>
                        </div>
                    </div>
                </div>
            </section >
        </>
    )
}

const servicesData = [
    {
        title: "Neha Bansal",
        text: "I have been using their services for the last 6 months and I  have never faced any issues with the connectivity. I would say their services are superb and outstanding.",
        clientIcon: ImageIcons.client2,
        clientAlt: 'client 1',
        designation: 'Customer',
    },
    {
        title: "Rahul Khanna",
        text: "Excellent services and customer support. They are the best internet services providers in Mohali, as they offer reliable services at very affordable rates.",
        clientIcon: ImageIcons.client1,
        clientAlt: 'client 2',
        designation: 'Customer',
    },
    {
        title: "Aryan",
        text: "Came to know about them from a friend and now I am loving their services. Doing work from home has become convenient and easy only because of them.",
        clientIcon: ImageIcons.client3,
        clientAlt: 'client 3',
        designation: 'Customer',
    }
]
export default Testimonial