import React, { useState, useRef, useEffect } from 'react';
import Layout from '../components/shared/layout'
import InnerPageBanner from '../components/shared/innerPageBanner';
import ImageIcons from '../components/imageComponent/ImageIcons';
import { FaMinus } from "react-icons/fa";
import { FaPlus } from "react-icons/fa";
import { IoCheckmarkDoneSharp } from 'react-icons/io5';
import SEO from '../components/shared/seo';

const FAQ = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const handleToggle = (index) => {
    setActiveIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  return (
    <>
      <SEO title='FAQs - My Internet | Your Questions Answered' description='Find answers to all your questions about My Internet. Get detailed information on our services, pricing, support, and more in our comprehensive FAQ section.' />
      <Layout>
        <InnerPageBanner title='Frequently Asked Questions' innerPageBanner={ImageIcons.FAQs} />
        <section>
          <div className="container !max-w-[1100px]">
            <div className="mx-auto bg-white   rounded-lg">

              {faqs.map((faq, index) => (
                <AccordionItem
                  key={index}
                  active={activeIndex === index}
                  handleToggle={() => handleToggle(index)}
                  faq={faq}
                />
              ))}
            </div>
          </div>
        </section>
      </Layout>
    </>
  );
};

const AccordionItem = ({ handleToggle, active, faq }) => {
  const contentRef = useRef(null);
  const [height, setHeight] = useState('0px');

  useEffect(() => {
    if (contentRef.current) {
      setHeight(active ? `${contentRef.current.scrollHeight}px` : '0px');
    }
  }, [active]);

  return (
    <div className="mb-[20px] shadow-[0px_3px_15px_0px_rgba(0,0,0,0.1)] overflow-hidden rounded-[10px]">
      <div
        className={`p-[20px] flex justify-between items-center cursor-pointer ${active ? 'bg-[#f48533] text-white' : ''}`}
        onClick={handleToggle}
      >
        <h5 className="mb-0 lg:text-[20px] text-[18px] ">{faq.question}</h5>
        <span>{active ? <FaMinus /> : <FaPlus />}</span>
      </div>
      <div
        ref={contentRef}
        className={`overflow-hidden transition-all duration-300`}
        style={{ maxHeight: height }}
      >
        <div className="p-[20px] ">
          <p className='mb-0'>{faq.answer}</p>

          {faq.answerList && <ul className='mt-[15px]'>
            {faq.answerList.map((faq, i) => (
              <li className='mb-[8px] text-[16px] relative pl-[25px]'><IoCheckmarkDoneSharp className='text-[17px] inline-block text-[#f48533] absolute left-0 top-[5px]' /> {faq}</li>
            ))}
          </ul>
          }
        </div>
      </div>
    </div>
  );
};

const faqs = [
  {
    question: "What is My Internet?",
    answer: "My Internet service providers are a leading ISP. We offer high-speed, reliable and affordable services for both residential and commercial purposes."
  },
  {
    question: "What are the Benefits of my Internet Fibre Broadband?",
    answer: "There are many benefits of my Internet service providers, they offer-",
    answerList:
      ["Blazing-fast speed", "Round the clock customer support.", "Wi-Fi calling.", "Fixed landline along with unlimited calls.", "Round the clock monitoring of the network.", "Routers which can troubleshoot by themselves."]
  },
  {
    question: "How can I register for a new broadband connection?",
    answer: "You need to select the log in section in the menu bar of our website, where you have to click on register yourself and after submitting the details, you will receive a call from our customer support team. Following this, you will be provided with plans and packages and then you can select a plan for yourself."
  },
  {
    question: "Do I need to provide any documentation for broadband connection?",
    answer: "Yes, you need to provide your identity proof and your address."
  },
  {
    question: "How can I check fibre broadband connection availability in my area?",
    answer: "For this, you need to fill in your details like name, contact number, city and installation address to register yourself. After this, you will receive a call from our customer support team and you can get your questions answered."
  },
  {
    question: "How much time does it take for an internet connection to get active?",
    answer: "It usually takes 48 hours for an internet connection to get stabilised. If you experience any initial hiccups or slow speed at initial speed, there is no need to worry, as at the initial stage, the network optimises itself."
  },
  {
    question: "In what  areas, the services of my internet are available?",
    answer: "My Internet was launched in 2016 by providing internet services all over the Punjab. Now our services are available in more than 18 states and we have a community of 57000+ customers."
  },
  {
    question: "Do you offer any additional benefits?",
    answer: "Yes users are offered with many discounts and benefits. One of the greatest advantages offered  is that we provide our IPTV services free of cost with internet services subscription."
  },
  {
    question: "What are the charges of My Internet service providers?",
    answer: "The charges are completely dependent upon the plan chosen and for this, you need to visit our website and check our plans."
  },
  {
    question: "Do I need to sign a contract?",
    answer: "No there is no contract and users are provided with full liberty to cancel their subscription whenever they want."
  },
  {
    question: "Is it possible to change the plan?",
    answer: "Yes absolutely, if you want to upgrade your plan, you can do it anytime."
  },
  {
    question: "Is it possible to schedule installation online?",
    answer: "Yes through our website, you can book an online appointment by contacting our customer support service."
  },
  {
    question: "What equipment is required for getting internet connection installed?",
    answer: "The modem and router are provided as a part of our plan. You are also allowed to use your own compatible equipment."
  },
  {
    question: "How can I contact the technical support team?",
    answer: "Our customer support team is available round the clock, so you can contact them via phone, email or live chat options provided on our website"
  },
  {
    question: "What should I do in case I face issues in my internet connectivity?",
    answer: "At first, you should restart your modem or router, however, if the problem persists, contact our customer support team."
  },

];

export default FAQ