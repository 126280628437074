import React from 'react';
import ImageIcons from "../imageComponent/ImageIcons"


const Markers = () => {
    return (
        <img
            src={ImageIcons?.Marker}
            alt='marker'
            style={{ width: '35px', height: '35px', cursor: 'pointer' }}

        />
    )
}

export default Markers;
