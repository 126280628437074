import React from 'react';
import Layout from '../components/shared/layout';
import InnerPageBanner from '../components/shared/innerPageBanner';
import ImageIcons from '../components/imageComponent/ImageIcons';
import { FaCheckSquare } from "react-icons/fa";
import { Link } from 'react-router-dom';
import { windowScroll } from '../components/utils/windowScroll';
import SEO from '../components/shared/seo';

const Broadband = () => {
  return (
    <>
<SEO title='Affordable High-Speed Internet & Broadband Plans | myinternetzone' description='Get  affordable internet broadband plans with top speeds with My Internet. Check out the latest broadband offers and prices for reliable WiFi broadband services' keywords='internet broadband plan, broadband offers, broadband internet prices, internet broadband, broadband services, fast broadband, wifi broadband, broadband wireless, broadband speeds, wifi broadband plans, wifi broadband connecrion price'/>
    <Layout>
      <InnerPageBanner title='Only Broadband' innerPageBanner={ImageIcons?.Broadband} />

      <section>
        <div className='container'>
          <div className='max-w-[800px] md:mb-[50px] mb-[40px] text-center mx-auto'>
            <div className='text-[#f48533] uppercase mb-[5px] '>Broadband Tables</div>
            <h2>Only <span className='text-[#f48533]'>Broadband</span></h2>
          </div>
          {BroadbandData.map((item, i) => (
            <div className='flex items-center max-md:flex-wrap gap-[20px] max-lg:gap-[15px] justify-between shadow-[0px_2px_15px_0px_rgba(0,0,0,0.1)] bg-[#fff] mb-[20px] p-[20px] max-lg:p-[15px] max-md:p-[20px]' key={i}>
              <div className='w-[200px] max-lg:w-[180px] max-sm:w-[120px]'>
                <svg className="w-full h-full" viewBox="0 0 100 100">
                  <circle
                    className="text-gray-200 stroke-current"
                    strokeWidth="5"
                    cx="50"
                    cy="50"
                    r="47"
                    fill="transparent"
                  ></circle>
                  <circle
                    className="text-[#f48533]  progress-ring__circle stroke-current"
                    strokeWidth="5"
                    strokeLinecap="round"
                    cx="50"
                    cy="50"
                    r="47"
                    fill="transparent"
                    strokeDashoffset={`calc(300 - (300 * ${item.speed}) / 300)`}
                  ></circle>
                  <text x="50" y="50" fontSize="18" textAnchor="middle" alignmentBaseline="middle">
                    <tspan x="50" dy="0" fontWeight={600} fill="#30353a">{item.speed}</tspan>
                    <tspan x="50" dy="15" fontSize="12" fill="#818487">Mbps</tspan>
                  </text>
                </svg>
              </div>
              <div className='w-[20%] max-md:w-[calc(100%_-_200px)] max-sm:w-[calc(100%_-_135px)]'>
                <h3 className='text-[#30353a] mb-0'>{item.packageName}</h3 >
              </div>
              <div className='w-[40%] max-md:w-[100%] max-md:mt-[10px]'>
                <ul>
                  <li className='mb-[8px] text-[#14212bb3]'><FaCheckSquare className='inline-block text-[#f48533] text-[20px] mr-[2px]' /> <span className='font-medium'>Data:</span> {item.data}</li>
                  <li className='mb-[8px] text-[#14212bb3]'><FaCheckSquare className='inline-block text-[#f48533] text-[20px] mr-[2px]' /> <span className='font-medium'>Validity:</span> {item.validity}</li>
                </ul>
              </div>
              <div className='min-w-[280px] max-xl:min-w-[250px] max-md:min-w-[100%] max-lg:min-w-[225px] max-md:mt-[20px] bg-[#fff3ea] text-center py-[25px] px-[10px]'>
                <div className='mb-[10px] max-md:mb-[15px]'>RS.<span className='lg:text-[54px] md:text-[48px] sm:text-[38px] text-[32px] font-bold	text-[#30353a]'>{item.monthPrice}</span></div>
                <Link to='/contact-us' onClick={windowScroll} className="px-[40px] text-[17px] py-[13px] text-[#fff] bg-[#f48533] rounded-[10px] hover:bg-[#000] ease inline-block">
                  Get Started</Link>
              </div>
            </div>
          ))}
        </div>
      </section>
    </Layout>
    </>
  )
}

const BroadbandData = [
  {
    packageName: "Super saver",
    monthPrice: "399",
    speed: "40",
    data: "Unlimited",
    validity: "1 Month"
  },
  {
    packageName: "Silver",
    monthPrice: "499",
    speed: "100",
    data: "Unlimited",
    validity: "1 Month"
  },
  {
    packageName: "Gold",
    monthPrice: "599",
    speed: "150",
    data: "Unlimited",
    validity: "1 Month"
  },
  {
    packageName: "Diamond",
    monthPrice: "699",
    speed: "200",
    data: "Unlimited",
    validity: "1 Month"
  },
  {
    packageName: "Platinum",
    monthPrice: "799",
    speed: "250",
    data: "Unlimited",
    validity: "1 Month"
  },

]

export default Broadband