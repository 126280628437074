import React from 'react'
import Button from '../shared/button';
import ImageIcons from '../imageComponent/ImageIcons';

const FAQ = () => {

  return (
    <section className='relative pt-0 lg:pb-[40px] pb-[20px]'>
      <div className='container'>
        <div className='bg-[#633b25] text-white md:p-[50px] p-[30px] flex md:flex-nowrap flex-wrap justify-between items-center gap-[25px] rounded-[10px] bg-cover bg-no-repeat' style={{ backgroundImage: `url(${ImageIcons.faqBg})` }}>
          <div>
            <h2 className='font-medium mb-[10px]'>Get Questions</h2>
            <p className='md:text-[25px] text-[18px] mb-0'>Check out our FAQ's section for help</p>
          </div>

          <Button hrefLink='/faqs' title="Read FAQ's" />
        </div>
      </div>
    </section>
  )
}

export default FAQ;