import React from 'react'
import ImageIcons from '../imageComponent/ImageIcons';
import Button from '../shared/button';

const Investors = () => {

  return (
    <section className="relative md:mt-[20px] mt-[10px]">
      <div className='container'>
        <div className='grid md:grid-cols-2 grid-cols-1 gap-[40px] items-center'>
          <div className='md:min-h-[500px] sm:min-h-[400px] md:ml-[25px] ml-[15px] min-h-[300px] relative after:content-[""] after:absolute after:rounded-[20px] md:after:top-[-25px] after:top-[-15px] md:after:left-[-25px] after:left-[-15px] after:w-[calc(100%_-_50%)] after:z-[-1] after: after:bg-[#f38432] after:h-full'>
            <img className='object-cover h-full w-full absolute rounded-[20px]' src={ImageIcons.investor} alt='Investor' />
          </div>
          <div className=''>
            <h2 className='font-bold text-[#f38432]'>Investor's Dock</h2>
            <p className='mb-[3 0px] lg:text-[20px] text-[18px]'>MyInternet is currently offering bright Investment Opportunities for those who believe in the power of technology in the coming future. Download the Investor's Dock and know a little more about how you can be a part of the big thing.</p>
            <Button hrefLink='/contact-us' title='Request Proposal' />
          </div>
        </div>
      </div>
    </section>
  )
}


export default Investors