import React, { useState, useRef, useEffect } from 'react'
import Layout from '../components/shared/layout';
import { IoSearch } from "react-icons/io5";
import { Link, useLocation } from 'react-router-dom';
import axios from 'axios';
import servicesData from "../components/utils/data/service.json";

const Searchs = () => {

    const [blogData, setBlogData] = useState([]);
    const [filteredBlogs, setFilteredBlogs] = useState([]);
    const [filteredServices, setFilteredServices] = useState([]);
    const [searchTerm, setSearchKey] = useState("");

    console.log("searchTerm", searchTerm);

    const fetchBlogs = async () => {
        const response = await axios.get(`${process.env.REACT_APP_URL}user/getblog`);
        console.log("search", response.data.data);
        setBlogData(response.data.data);
    }

    useEffect(() => {
        fetchBlogs();
    }, []);

    useEffect(() => {
        const filteredResult = Array.isArray(blogData) && blogData.length > 0
            ? blogData.filter((blogItem) =>
                (blogItem.isActive === true) &&
                (blogItem.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
                    blogItem.description.toLowerCase().includes(searchTerm.toLowerCase()) ||
                    blogItem.author.toLowerCase().includes(searchTerm.toLowerCase()))
            )
            : [];
        setFilteredBlogs(filteredResult);
    }, [searchTerm, blogData]);

    useEffect(() => {
        const filteredServiceResult = Array.isArray(servicesData) && servicesData.length > 0
            ? servicesData.filter((serviceItem) => {
                const searchTermLower = searchTerm.toLowerCase();
                const additionalInfoMatches = serviceItem?.additionalInfo?.some(
                    (info) =>
                        info.title?.toLowerCase().includes(searchTermLower) ||
                        info.description?.toLowerCase().includes(searchTermLower)
                );
                const slowFeaturesMatches = serviceItem?.features?.some(
                    (feature) =>
                        feature.title?.toLowerCase().includes(searchTermLower) ||
                        feature.description?.toLowerCase().includes(searchTermLower)
                );
                const fastFeaturesMatches = serviceItem?.plans?.some(
                    (feature) =>
                        feature?.title?.toLowerCase().includes(searchTermLower) ||
                        feature?.description?.toLowerCase().includes(searchTermLower)
                );
                return (
                    serviceItem?.heading?.toLowerCase().includes(searchTermLower) ||
                    serviceItem?.type?.toLowerCase().includes(searchTermLower) ||
                    serviceItem?.title?.toLowerCase().includes(searchTermLower) ||
                    serviceItem?.description?.toLowerCase().includes(searchTermLower) ||
                    serviceItem?.headingdescription?.toLowerCase().includes(searchTermLower) ||
                    additionalInfoMatches ||
                    slowFeaturesMatches ||
                    fastFeaturesMatches
                );
            })
            : [];
        setFilteredServices(filteredServiceResult);
    }, [searchTerm, servicesData]);

    const searchDropdownRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (searchDropdownRef.current && !searchDropdownRef.current.contains(event.target)) {
                setSearchKey('');
            }
        };

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [searchDropdownRef]);

    function resetHandler() {
        setSearchKey("");
        setFilteredServices([]);
    }

    const { state } = useLocation();
    const searchTerm1 = state?.searchTerm; // Accessing the searchTerm from state
    console.log("searchTerm=============>>>>>>>>>.....", searchTerm1)
    useEffect(() => {
        if (searchTerm1) {
            setSearchKey(searchTerm1);
        }
    }, [searchTerm1]);

    const handleFormSubmit = (e) => {
        e.preventDefault();
    }

    return (
        <>
            <Layout>
                <div className='w-full md:py-[70px] max-md:py-[40px]'>
                    <form onSubmit={handleFormSubmit}>
                        <div className="container mx-auto px-2">
                            <div className="relative w-full max-w-[992px] mx-auto" ref={searchDropdownRef}>
                                <input type="text" id="search-dropdown" className="focus:outline-none bg-[#fff] text-[#000] h-[45px]  rounded-[2px] block w-full md:px-5 max-md:px-3 md:py-[8px] max-md:py-[6px] pe-[40px] border border-gray-400" placeholder="Search..."
                                    value={searchTerm}
                                    onChange={(e) => setSearchKey(e.target.value)}
                                />
                                <div type="submit" className="absolute h-[45px] w-[45px] py-[8px] right-0 top-0 flex items-center justify-center text-white bg-[#000] rounded-r-[2px] cursor-pointer hover:bg-[#FD5901] duration-500">
                                    <IoSearch className='md:text-[18px] max-md:text-[16px]' />
                                </div>
                            </div>
                        </div>
                    </form>

                    {filteredBlogs.length === 0 && filteredServices.length === 0 ? (
                        <div className='container mx-auto px-2'>
                            <div className="text-center md:mt-[50px] max-md:mt-[30px]">
                                <h4 className="cursor-pointer hover:text-[#FD5901]">Not found results</h4>
                            </div>
                        </div>
                    ) : (
                        <>
                            <div className="w-full md:mt-[50px] max-md:mt-[35px]">
                                <div className="container mx-auto px-2">
                                    {filteredBlogs?.map((blogItem) => (
                                        <div key={blogItem?._id} className="bg-[#fff] rounded p-4 shadow-[0px_0px_15px_0px_rgba(0,0,0,0.15)] md:mb-[30px] max-md:mb-[20px] last:mb-[0px]">
                                            <div className="lg:p-2">
                                                <Link
                                                    onClick={resetHandler}
                                                    className='block text-black group'
                                                    to={`/blogs/${blogItem?.handle?.replace(/\s+/g, "-").toLowerCase()}`}
                                                >
                                                    <h4 className="mb-3 cursor-pointer group-hover:text-[#FD5901] duration-500">{blogItem?.title}</h4>
                                                    <p className='cursor-pointer sibling:!mb-0 mb-0' dangerouslySetInnerHTML={{ __html: blogItem?.description.slice(0, 150) }}></p>

                                                </Link>
                                            </div>
                                        </div>
                                    ))}

                                    {filteredServices?.map((serviceItem) => (
                                        <div key={serviceItem?._id} className="bg-[#fff] rounded p-4 shadow-[0px_0px_15px_0px_rgba(0,0,0,0.15)] md:mb-[30px] max-md:mb-[20px] last:mb-[0px]">
                                            <div className="lg:p-2">
                                                <Link
                                                    onClick={resetHandler}
                                                    className='block text-black group'
                                                    to={`/service/${serviceItem?.type?.replace(/\s+/g, "-").toLowerCase()}`}
                                                >
                                                    <h4 className="mb-3 cursor-pointer group-hover:text-[#FD5901] duration-500">{serviceItem?.title.slice(0, 20)}...</h4>
                                                    <p className='cursor-pointer mb-[10px]'>{serviceItem?.headingdescription.slice(0, 300)}.....</p>
                                                    <p className='cursor-pointer mb-0 uppercase'>{serviceItem?.services}</p>
                                                </Link>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </>
                    )}

                </div>
            </Layout>
        </>
    );
};

export default Searchs;
