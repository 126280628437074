import logo from "../../assets/images/logo.webp";
import mailIcon from "../../assets/icons/mail-icon.webp";
import sendIcon from "../../assets/icons/send-icon.webp";
import freeHotspot from "../../assets/images/free_hotspot.webp";
import netIptvOtt from "../../assets/images/net_iptv_ott.webp";
import superfastHomeBroadbandPlans from "../../assets/images/superfast_home_broadband_plans.webp";
import empowerYourBusiness from "../../assets/images/empower_your_business.webp";
import globShape from "../../assets/images/glob-shape.webp";
import myinternetBanner from "../../assets/images/myinternet-banner.webp";
import dreamInternet from "../../assets/images/dreaminternet.webp";
import servicesBg from "../../assets/images/services-bg.webp";
import testimonialBg from "../../assets/images/testimonial-bg.webp";
import client1 from "../../assets/images/client/client1.webp";
import client2 from "../../assets/images/client/client2.webp";
import client3 from "../../assets/images/client/client3.webp";
import client4 from "../../assets/images/client/client4.webp";
import testimonialBanner from "../../assets/images/testimonial-banner.webp";
import investor from "../../assets/images/investor.webp";
import expansion from "../../assets/images/expansion.webp";
import plansBg from "../../assets/images/plans_bg.webp";
import contactBg from "../../assets/images/contact_bg.webp";
import ranjeetSingh from "../../assets/images/ranjeet_singh_sandhu.webp";
import inneraboutShape from "../../assets/images/inner-about-shape-img.webp";
import ceo from "../../assets/images/about/ceo.webp";
import cto from "../../assets/images/about/cto.webp";
import president from "../../assets/images/about/president.webp";
import leadershipBg from "../../assets/images/leadership_bg.webp";
import aboutShape from "../../assets/images/about-shape.webp";
import visionInternetImg from "../../assets/images/vision-internet-img.webp";
import visionInternet from "../../assets/images/vision-internet.webp";
import internetNecessity from "../../assets/images/internet-necessity.webp";
import investment from "../../assets/images/investment.webp";
import philanthropy from "../../assets/images/philanthropy.webp";
import broadband from "../../assets/images/services/broadband.webp";
import cdnServices from "../../assets/images/services/cdn-services.webp";
import cloudCam from "../../assets/images/services/cloud-cam.webp";
import iptv from "../../assets/images/services/iptv.webp";
import leaseLine from "../../assets/images/services/lease-line.webp";
import manahedNetwork from "../../assets/images/services/manahed_network.webp";
import voipIntercom from "../../assets/images/services/voip-intercom.webp";
import wifiHotspots from "../../assets/images/services/wifi-hotspots.webp";
import registerImg from "../../assets/images/register.webp";
import help from "../../assets/images/help.webp";
import theme1 from "../../assets/images/theme1.webp";
import theme2 from "../../assets/images/theme2.webp";
import theme3 from "../../assets/images/theme3.webp";
import theme4 from "../../assets/images/theme4.webp";
import theme5 from "../../assets/images/theme5.webp";
import ghostWhite from "../../assets/images/ghost-white.gif";
import jobOpening from "../../assets/images/job-opening.webp";
import hiring from "../../assets/images/hiring.webp";
import faqBg from "../../assets/images/faq_bg.webp";
import advantagesInternetLeased from "../../assets/images/advantages_internet_leased.webp";
import chooseLeasedLine from "../../assets/images/choose_leased_line.webp";
import cityImg from "../../assets/images/city_img.webp";
import unlimited from "../../assets/images/city/unlimited.webp";
import support from "../../assets/images/city/support.webp";
import speed from "../../assets/images/city/speed.webp";
import device from "../../assets/images/city/device.webp";
import insights from "../../assets/images/city/insights.webp";
import flexible from "../../assets/images/city/flexible.webp";
import franchiseeImg from "../../assets/images/franchisee_img.webp";
import Broadband from "../../assets/images/Broadband.webp";
import intertainment from "../../assets/images/intertainment.webp"
import Franchisee from "../../assets/images/Franchisee.webp";
import Aboutus from "../../assets/images/Aboutus.webp";
import Vision from "../../assets/images/Vision.webp";
import Career from "../../assets/images/Career.webp";
import Contactus from "../../assets/images/Contactus.webp";
import FAQs from "../../assets/images/FAQs.webp";
import PrivacyPolicy from "../../assets/images/PrivacyPolicy.webp";
import TermCondition from "../../assets/images/Term&Condition.webp";
import RefundPolicy from "../../assets/images/RefundPolicy.webp";
import Disclaimer from "../../assets/images/Disclaimer.webp";
import Cities from "../../assets/images/Cities.webp";
import Marker from "../../assets/images/marker.webp";
import entertainment from "../../assets/images/entertainment.webp"
import getMyInternet from "../../assets/images/get_my_internet.webp"
 
const ImageIcons = {
  Franchisee,
  Aboutus,
  Vision,
  logo,
  Career,
  Contactus,
  FAQs,
  PrivacyPolicy,
  TermCondition,
  RefundPolicy,
  Disclaimer,
  Cities,
  mailIcon,
  sendIcon,
  freeHotspot,
  netIptvOtt,
  superfastHomeBroadbandPlans,
  empowerYourBusiness,
  globShape,
  myinternetBanner,
  dreamInternet,
  servicesBg,
  testimonialBg,
  client1,
  client2,
  client3,
  client4,
  testimonialBanner,
  investor,
  expansion,
  plansBg,
  contactBg,
  ranjeetSingh,
  inneraboutShape,
  ceo,
  cto,
  president,
  leadershipBg,
  aboutShape,
  visionInternetImg,
  visionInternet,
  internetNecessity,
  investment,
  philanthropy,
  broadband,
  cdnServices,
  cloudCam,
  iptv,
  leaseLine,
  manahedNetwork,
  voipIntercom,
  wifiHotspots,
  registerImg,
  help,
  theme1,
  theme2,
  theme3,
  theme4,
  theme5,
  ghostWhite,
  jobOpening,
  hiring,
  faqBg,
  advantagesInternetLeased,
  chooseLeasedLine,
  cityImg,
  unlimited,
  support,
  speed,
  device,
  insights,
  flexible,
  franchiseeImg,
  Broadband,
  Marker, 
  intertainment,
  entertainment,
  getMyInternet
};

export default ImageIcons;