import React, { useState, useEffect } from 'react';
import axios from "axios";
import { Link } from 'react-router-dom';
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import { windowScroll } from '../../components/utils/windowScroll';


const Cities = () => {
    const [view, setView] = useState(false);
    const [data, setData] = useState([]);
    useEffect(() => {

        const fetchData = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_URL}user/getcities`);
                setData(response?.data?.data);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData()
    }, [])

    console.log("data.....", data)

    return (
        <>
            <div className="w-full pb-[20px] mt-[50px]">
                <div className="container mx-auto">
                    <div className="mb-[40px]">
                        <h2 className="text-center mb-[30px]">Popular Cities</h2>
                        <div className="grid lg:grid-cols-6 sm:grid-cols-3 grid-cols-2 sm:gap-[40px] gap-[30px]">

                            {data?.map((item, index) => (
                                <div className="text-center ">
                                    <Link to={`/cities/${item?.handle}`}>
                                        <img className="max-w-[200px] w-full mx-auto" src={item?.image2} alt="cities" />

                                        <h5 className="mt-[15px] max-lg:text-[18px] mb-0">{item?.cityname}</h5>
                                    </Link>

                                </div>
                            ))}

                        </div>
                    </div>
                </div>
            </div>

            {view ?
                <div className="w-full md:pb-[70px] max-md:pb-[40px]">
                    <div className="container mx-auto">
                        <div>
                            <div className='text-center'>
                                <button onClick={() => setView(false)} className="inline-flex justify-center items-center gap-[10px] duration-[400ms,700ms] px-[30px] py-3 bg-[#FD5901] hover:bg-[#ffffff] border-2 border-[#FD5901] hover:text-black hover:border-2 hover:border-[#FD5901] text-white rounded-full mb-[20px] cursor-pointer">
                                    Hide All States/Cities <span><FaChevronUp /></span> {/* <span><FaChevronUp /></span> */}
                                </button>
                            </div>
                            <div >

                                <div className='container  '>
                                    <div className="grid max-sm:grid-cols-1 max-md:grid-cols-2 max-lg:grid-cols-4 lg:grid-cols-7 gap-x-[20px] gap-y-[10px] max-w-full mx-auto pt-[10px] text-center  ">
                                        {data?.map((item) => {
                                            return (
                                                <div>
                                                    <Link to={`/cities/${item?.handle}`} onClick={windowScroll} >
                                                        <p className=''>{item?.cityname}</p>
                                                    </Link>
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                :
                <div className="w-full md:pb-[70px] max-md:pb-[40px]">
                    <div className="container mx-auto">
                        <div>
                            <div className='text-center'>
                                <button onClick={() => setView(true)} className="inline-flex justify-center items-center gap-[10px] duration-[400ms,700ms] px-[30px] py-3 hover:bg-[#f48533] bg-[#ffffff] border-2 border-[#f48533] text-black hover:border-2 hover:text-white rounded-full mb-[20px] cursor-pointer">
                                    View All States/Cities <span><FaChevronDown /></span> {/* <span><FaChevronUp /></span> */}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            }
            {/* </div> */}

        </>
    )

}
export default Cities;