import React, { useEffect, useState } from 'react'
import { Link } from "react-router-dom";
import InnerPageBanner from '../components/shared/innerPageBanner';
import ImageIcons from '../components/imageComponent/ImageIcons';
import Layout from '../components/shared/layout';
import axios from 'axios';
import SEO from '../components/shared/seo';

const SiteMap = () => {
    const [, setAllBlogs] = useState([])


    const fetchBlogs = async () => {
        const response = await axios.get(`${process.env.REACT_APP_URL}user/getblog`);
        const filteredData = response.data.data.filter(item => item.isActive === true);
        const sortedBlogs = filteredData.sort((a, b) => new Date(b.publishedAt) - new Date(a.publishedAt));
        setAllBlogs(sortedBlogs)
    }

    useEffect(() => {
        fetchBlogs()
    }, [])
    return (
        <>
            <SEO
        title="My Internet Zone Sitemap - Navigate Our Services Easily"
        description="Explore the complete sitemap of My Internet Zone. Easily find links to all our services, pricing, blogs, and contact information."
        keywords='My Internet Zone sitemap, website navigation, site map, website structure'
      /> 
        <Layout>
            <InnerPageBanner title='Sitemap' innerPageBanner={ImageIcons.contactBg} />
            <section>
                <div className="container">
                    <ul className="text-center">
                        <li>
                            <label className='bg-[#000] w-full relative p-[12px] mb-[20px] inline-block after:absolute after:contant-[""] after:w-[1px] after:h-[20px] after:bg-[#000] after:top-full after:left-[50%] after:-translate-x-2/4'><Link className='text-[22px] text-white hover:text-[#f48533]' to="/">Home</Link></label>
                            <ul className="flex justify-center max-md:flex-wrap max-md:border max-md:border-[#000] max-md:pb-[20px]">
                                {navigationData.map((item, index) => (
                                    <li key={index} className='relative px-[10px] max-md:w-[90%] max-md:p-0 first:pl-0 last:pr-0 first:after:w-[50%] last:after:w-[50%] after:absolute after:contant-[""] after:w-full after:border-t after:border-[#000] after:top-0 after:right-0 last:after:left-0 before:absolute before:contant-[""] before:w-[1px] before:h-[20px] before:bg-[#071e43] before:top-0 before:left-[50%] before:-translate-x-2/4 max-md:after:hidden max-md:before:hidden'>
                                        {item.title === "Blogs" ? (
                                            <Link to={item.type}  onClick={() => { window.location.pathname = item.type }} className='text-white text-[18px] w-full text-center min-w-[100px] bg-[#000] py-[12px] px-[20px] inline-block mt-[20px] underline hover:text-[#f48533]'>{item.title}</Link>
                                        ) : (
                                            <label className='text-white text-[18px] w-full text-center min-w-[100px] hover:text-[#fff] bg-[#141416] py-[12px] px-[20px] inline-block mt-[20px]'>{item.title}</label>
                                        )}
                                        {item.subMenu && (
                                            <ul className="max-md:border max-md:border-[#141416] max-md:pb-[20px] max-md:mt-[19px]">
                                                {item.subMenu.map((subItem, subIndex) => (
                                                    <li className='relative max-md:w-[90%] max-md:mx-auto after:absolute after:hidden first:after:block contant-[""] after:w-[1px] after:h-[20px] after:bg-[#141416] after:top-0 max-md:after:top-[-20px] after:left-[50%] after:-translate-x-2/4 first:pt-[20px] pt-[10px]' key={subIndex}>
                                                        {subItem.type &&
                                                            <Link className='text-[#141416] hover:bg-[#f48533] border w-full text-center min-w-[100px] hover:text-[#fff] border-[#f48533] py-[12px] px-[20px] inline-block rounded-[8px] ' to={`/service/${subItem.type}`} onClick={() => { window.location.pathname = `service/${subItem?.type}` }}
                                                            >{subItem.title}</Link>
                                                        }
                                                        {subItem.handle &&
                                                            <Link className='text-[#141416] hover:bg-[#f48533] border w-full text-center min-w-[100px] hover:text-[#fff] border-[#f48533] py-[12px] px-[20px] inline-block rounded-[8px] ' to={`/pricing/${subItem.handle}`} onClick={() => { window.location.pathname = `pricing/${subItem?.handle}` }}
                                                            >{subItem.title}</Link>
                                                        }
                                                        {subItem.handleother &&
                                                            <Link className='text-[#141416] hover:bg-[#f48533] border w-full text-center min-w-[100px] hover:text-[#fff] border-[#f48533] py-[12px] px-[20px] inline-block rounded-[8px] ' to={subItem?.handleother} onClick={() => { window.location.pathname = subItem.handleother }}
                                                            >{subItem.title}</Link>
                                                        }
                                                    </li>


                                                ))}
                                            </ul>
                                        )}
                                    </li>
                                ))}
                            </ul>
                        </li>
                    </ul>
                </div>
            </section>
        </Layout>
        </>
    )
}

const navigationData = [
    {
        title: "Services",
        subMenu: [
            {
                title: "Internet Leased Line",
                type: "internet-leased-line"
            },
            {
                title: "Business Internet Providers",
                type: "business-internet-providers"
            },
            {
                title: "Home Internet Providers",
                type: "home-internet-providers"
            },
            {
                title: "IPTV",
                type: "iptv"
            },
            {
                title: "Free Wifi Hotspots",
                type: "free-wifi-hotspots"
            },
            {
                title: "Managed Network",
                type: "managed-network"
            },
            {
                title: "Cloud Cam",
                type: "cloud-cam"
            },
            {
                title: "VoIP Intercom",
                type: "VoIP-intercom"
            },
            {
                title: "CDN Services",
                type: "cdn-services"
            },

        ]
    },
    {
        title: "Packages",
        subMenu: [
            {
                title: "Broadband",
                handle: "broadband"
            },
            {
                title: "Custom Plan",
                handle: "custom-plan"
            },
            {
                title: "OTT",
                handle: "ott"
            },
            {
                title: "Entertainment Combo",
                handle: "entertainment-combo"
            },
            {
                title: "Business Internet",
                handle: "business-internet"
            },
            {
                title: "Home Internet",
                handle: "home-internet"
            },
        ]
    },
    {
        title: "Blogs",
        type: "/blogs",
        subMenu: [
            {
                title: "Free WiFi Hotspots-Malls & Business Parks",
                handleother: "/blogs/free-wifi-hotspots-malls-business-parks"
            },
            {
                title: "How to Choose the Best Internet Service Provider",
                handleother: "/blogs/how-to-choose-best-internet-service-provider"
            },
            {
                title: "Essential Features in Broadband Apps: A Consumer's Guide",
                handleother: "/blogs/essential-features-in-broadband-apps-consumers-guide"
            },

        ]
    },
    {
        title: "Other Pages",
        subMenu: [
            {
                title: "About Us",
                handleother: "/about-us"
            },
            {
                title: "Contact Us",
                handleother: "/contact-us"
            },
            {
                title: "Franchisee",
                handleother: "/franchisee"
            },
            {
                title: "Vision",
                handleother: "/vision"
            },
            {
                title: "Career",
                handleother: "/career"
            },
            {
                title: "FAQs",
                handleother: "/faqs"
            },
            {
                title: "Privacy Policy",
                handleother: "/privacy-policy"
            },
            {
                title: "Terms & Conditions",
                handleother: "/terms-conditions"
            },
            {
                title: "Refund Policy",
                handleother: "/refund-policy"
            },
            {
                title: "Lead Generation",
                handleother: "/get-myinternet"
            },
            {
                title: "Disclaimer",
                handleother: "/disclaimer"
            },
        ]
    },

];

export default SiteMap;