import React, { useState, useRef, useEffect } from 'react';
import Layout from '../shared/layout';
// import InnerPageBanner from '../shared/innerPageBanner'
import ImageIcons from '../imageComponent/ImageIcons'
import Button from '../shared/button';
import { FaMinus, FaPlus } from 'react-icons/fa';
import { IoCheckmarkDoneSharp } from 'react-icons/io5';
import { FaWifi } from 'react-icons/fa'
import { MdLiveTv } from 'react-icons/md'
import { Link, useParams, useNavigate } from 'react-router-dom';
import { useLocation } from "react-router-dom";
import axios from 'axios';
import SEO from '../shared/seo';
import servicesData from "../../components/utils/data/cities.json"
import { windowScroll } from '../utils/windowScroll';
import GoogleMap from 'google-maps-react-markers';
import Markers from './marker';

const CitiesDetail = () => {
    const [activeIndex, setActiveIndex] = useState(null);
    const { types } = useParams();
    const [data2, setData2] = useState([])


    const handleToggle = (index) => {
        setActiveIndex((prevIndex) => (prevIndex === index ? null : index));
    };

    const mapRef = useRef(null)

    const { pathname } = useLocation();
    const { handle } = useParams();

    const [city, setCity] = useState("")
    const [data, setData1] = useState([])
    const [faq, setFaq] = useState([])
    const navigate = useNavigate()
    console.log(">>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>.", data)



    console.log("city", city)





    const fetchBlogs = async () => {
        const response = await axios.get(`${process.env.REACT_APP_URL}user/getcities`);
        const filteredData = response?.data?.data?.filter(item => item?.isActive === true);
        const foundCity = filteredData?.find(city => pathname?.toLowerCase() === `/cities/${city?.handle}`);
        if (!foundCity) {
            navigate('*');
        } else {
            setCity(foundCity)
        }
    }


    useEffect(() => {
        fetchBlogs()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pathname, handle])


    const removeHTMLTags = (html) => {
        const doc = new DOMParser().parseFromString(html, 'text/html');
        return doc.body.textContent || "";
    };

    const fetchList = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_URL}user/getcitiesdetail/${city?._id}`);
            setData1(response?.data?.data);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    useEffect(() => {
        fetchList();
        // eslint-disable-next-line react-hooks/exhaustive-deps

    }, [city?._id]);
    const fetchfaqList = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_URL}user/getcityfaqs/${city?._id}`);
            setFaq(response?.data?.data);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };


    useEffect(() => {
        fetchfaqList();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [city?._id]);



    const [serviceDetails, setServiceDetails] = useState(null)

    useEffect(() => {
        const selectedService = servicesData.find(service => service.types === types);
        setServiceDetails(selectedService);
    }, [types])
    console.log("serviceDetails", serviceDetails)

    const [data3, setData3] = useState([]);
    console.log("data3>>>.", data3)

    const fetchData = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_URL}admin/getPackages`);
            console.log("response>>>", response)
            const filteredData = response?.data?.data?.filter(item => item.typeid.type === "Residental");
            console.log("filteredData", filteredData)
            setData3(filteredData);
            const filteredData1 = response?.data?.data?.filter(item => item.typeid.type === "Commercial");
            setData2(filteredData1);

        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    useEffect(() => {
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps

    }, []);

    const onGoogleApiLoaded = ({ map, maps }) => {
        mapRef.current = map
    }

    return (
        <>
            <SEO title={city?.metaTitle} description={city?.metaDescription} keywords={city?.keyword} />
            <Layout>
                <section>
                    <div className='container'>
                        <div className='grid lg:grid-cols-2 items-center gap-[40px]'>
                            <div>
                                <h1>{city?.title}</h1>
                                <p>{removeHTMLTags(city?.description)}</p>
                                <Button hrefLink='/contact-us' title='Get it now' />
                            </div>

                            <div>
                                <img className='lg:ml-auto max-lg:m-auto' src={ImageIcons.cityImg} alt='City' />
                            </div>
                        </div>
                    </div>
                </section>
                <div className="discover-nearby-col px-12"  >
                    <div className="discover-nearby-map-info container ">
                        <GoogleMap className="mappin-img-info m-auto"
                            apiKey="AIzaSyCkfOfMsbxXQJDddclN4qd_u6_l19bvpAc"
                            defaultCenter={{ lat: 20.5937, lng: 78.9629 }}
                            defaultZoom={4}
                            mapMinHeight="400px"
                            onGoogleApiLoaded={onGoogleApiLoaded}
                            alt="google_Map"

                        >
                            {data?.map(({ latitude, longitude, name }, index) => (
                                <Markers
                                    key={index}
                                    lat={latitude}
                                    lng={longitude}
                                    markerId={name}

                                />
                            ))}
                        </GoogleMap>
                    </div>
                </div>
                <section className="relative bg-[#F3F3F3]">
                    <div className='container'>
                        <div className='mb-[40px] max-w-[960px] text-center mx-auto'>
                            <h2>Broadband Plans in {city?.cityname}</h2>
                            <p>Experience the power of high-speed broadband with MyInternet in {city.cityname}! Seamlessly stream high-definition videos without buffering, making your movie nights and binge-watching sessions more enjoyable</p>
                            <h3 class="mb-0 text-center mt-[20px] uppercase text-[25px]">Home Internet Plans</h3>
                        </div>

                        <div className='flex max-sm:flex-col items-center gap-4 justify-center pt-[10px]'>
                            <div>Tell us where you live</div>
                            <select 
                                className='w-[260px] h-[50px] border border-[#f7dfcd] bg-[#f4853308] px-[15px] rounded-[5px] focus:outline-none focus:border-[#fdceaa]'>
                                <option value="">Select City</option>
                                {data?.map((item, i) => (
                                    <option value={item?.handle} >{item?.name}</option>
                                ))}
                            </select>
                        </div>

                        <div className='grid xl:grid-cols-4 lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-[30px] mt-[40px]'>
                            {data3?.map((item, i) => (
                                <div key={i} className='border border-[#f48533] rounded-[10px]'>
                                    <div className='text-center p-[20px]'>
                                        <div className='mb-2 text-[#f48533]'>Upto</div>
                                        <h2 className='mb-[0]'>{item?.speedid?.speed}</h2>
                                    </div>
                                    <div className='p-[30px] bg-[#f48533] mb-[20px]' style={{ clipPath: 'polygon(0% 0%, 101% 0%, 100% 80%, 50% 100%, 0% 80%)' }}>
                                        <ul className="flex items-center justify-center gap-[40px]">
                                            <li className='flex items-center flex-col relative after:last:hidden after:content-["+"] after:absolute after:font-bold after:text-[25px] after:text-[white] after:ml-[12.5px] after:left-full after:top-0'>
                                                <span className='w-[40px] h-[40px] bg-[#fff] flex items-center justify-center rounded-full mb-[10px]'><FaWifi /></span>
                                                <strong className='text-white'>Wi-Fi</strong>
                                            </li>
                                            <li className='flex items-center flex-col relative after:last:hidden after:content-["+"] after:absolute after:font-bold after:text-[25px] after:text-[white] after:ml-[12.5px] after:left-full after:top-0'>
                                                <span className='w-[40px] h-[40px] bg-[#fff] flex items-center justify-center rounded-full mb-[10px]'><MdLiveTv /></span>
                                                <strong className='text-white'>OTT</strong>
                                            </li>
                                            <li className='flex items-center flex-col relative after:last:hidden after:content-["+"] after:absolute after:font-bold after:text-[25px] after:text-[white] after:ml-[12.5px] after:left-full after:top-0'>
                                                <span className='w-[40px] h-[40px] bg-[#fff] flex items-center justify-center rounded-full mb-[10px]'><MdLiveTv /></span>
                                                <strong className='text-white'>IPTV</strong>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className='xxl:px-[30px] px-[20px] mb-[20px] '>
                                        <div className='flex items-center justify-between border-b border-dashed border-[#f7dfcd] pb-2 mb-2 text-[#f48533]'>
                                            <div>{item?.onemonth}</div>
                                            <h3 className='mb-0 xxl:text-[25px] lg:text-[22px] text-[20px]'>{item?.pricemonthsPriceThree}
                                                {/* <span className='text-[20px]'>mo*</span> */}
                                            </h3>
                                        </div>
                                        <div className='flex items-center justify-between border-b border-dashed border-[#f7dfcd] pb-2 mb-2'>
                                            <div>{item?.sixmonth}</div>
                                            <h3 className='mb-0 xxl:text-[25px] lg:text-[22px] text-[20px]'>{item?.monthsPriceSix}
                                                {/* <span className='text-[20px]'>mo*</span> */}
                                            </h3>
                                        </div>
                                        <div className='flex items-center justify-between'>
                                            <div>{item?.twelvemonth}</div>
                                            <h3 className='mb-0 xxl:text-[25px] lg:text-[22px] text-[20px]'>{item?.monthsPriceTwelve}
                                                {/* <span className='text-[20px]'>mo*</span> */}
                                            </h3>
                                        </div>
                                    </div>

                                    <div className='text-center px-[20px]'>
                                        <div>TV Channels</div>
                                        <ul>
                                            <li className='w-[50px] h-[50px] '><img src={ImageIcons.myinternetBanner} alt='Myinternet' /></li>
                                        </ul>
                                    </div>

                                    <div className='text-center mb-[30px]'>
                                        <Link className='mx-auto px-[25px] py-[12px] font-medium text-[#fff] bg-[#f48533] rounded-[10px] hover:bg-[#000] ease inline-block' to='/contact-us' onClick={windowScroll}>Buy Now</Link>
                                    </div>
                                </div>
                            ))}
                        </div>
                        <h3 className='mb-0 text-center mt-[40px] uppercase text-[25px]'>Business Internet Plans</h3>
                        <div className='grid xl:grid-cols-4 lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-[30px] mt-[40px]'>
                            {data2?.map((item, i) => (
                                <div key={i} className='border border-[#f48533] rounded-[10px]'>
                                    <div className='text-center p-[20px]'>
                                        <div className='mb-2 text-[#f48533]'>Upto</div>
                                        <h2 className='mb-[0]'>{item?.speedid?.speed}</h2>
                                    </div>
                                    <div className='p-[30px] bg-[#f48533] mb-[20px]' style={{ clipPath: 'polygon(0% 0%, 101% 0%, 100% 80%, 50% 100%, 0% 80%)' }}>
                                        <ul className="flex items-center justify-center gap-[40px]">
                                            <li className='flex items-center flex-col relative after:last:hidden after:content-["+"] after:absolute after:font-bold after:text-[25px] after:text-[white] after:ml-[12.5px] after:left-full after:top-0'>
                                                <span className='w-[40px] h-[40px] bg-[#fff] flex items-center justify-center rounded-full mb-[10px]'><FaWifi /></span>
                                                <strong className='text-white'>Wi-Fi</strong>
                                            </li>
                                            <li className='flex items-center flex-col relative after:last:hidden after:content-["+"] after:absolute after:font-bold after:text-[25px] after:text-[white] after:ml-[12.5px] after:left-full after:top-0'>
                                                <span className='w-[40px] h-[40px] bg-[#fff] flex items-center justify-center rounded-full mb-[10px]'><MdLiveTv /></span>
                                                <strong className='text-white'>OTT</strong>
                                            </li>
                                            <li className='flex items-center flex-col relative after:last:hidden after:content-["+"] after:absolute after:font-bold after:text-[25px] after:text-[white] after:ml-[12.5px] after:left-full after:top-0'>
                                                <span className='w-[40px] h-[40px] bg-[#fff] flex items-center justify-center rounded-full mb-[10px]'><MdLiveTv /></span>
                                                <strong className='text-white'>IPTV</strong>
                                            </li>
                                        </ul>
                                    </div>


                                    <div className='xxl:px-[30px] px-[20px] mb-[20px] '>
                                        <div className='flex items-center justify-between border-b border-dashed border-[#f7dfcd] pb-2 mb-2 text-[#f48533]'>
                                            <div>{item?.onemonth}</div>
                                            <h3 className='mb-0 xxl:text-[25px] lg:text-[22px] text-[20px]'>{item?.pricemonthsPriceThree}
                                                {/* <span className='text-[20px]'>mo*</span> */}
                                            </h3>
                                        </div>
                                        <div className='flex items-center justify-between border-b border-dashed border-[#f7dfcd] pb-2 mb-2'>
                                            <div>{item?.sixmonth}</div>
                                            <h3 className='mb-0 xxl:text-[25px] lg:text-[22px] text-[20px]'>{item?.monthsPriceSix}
                                                {/* <span className='text-[20px]'>mo*</span> */}
                                            </h3>
                                        </div>
                                        <div className='flex items-center justify-between'>
                                            <div>{item?.twelvemonth}</div>
                                            <h3 className='mb-0 xxl:text-[25px] lg:text-[22px] text-[20px]'>{item?.monthsPriceTwelve}
                                                {/* <span className='text-[20px]'>mo*</span> */}
                                            </h3>
                                        </div>
                                    </div>

                                    <div className='text-center px-[20px]'>
                                        <div>TV Channels</div>
                                        <ul>
                                            <li className='w-[50px] h-[50px] '><img src={ImageIcons.myinternetBanner} alt='Myinternet' /></li>
                                        </ul>
                                    </div>

                                    <div className='text-center mb-[30px]'>
                                        <Link className='mx-auto px-[25px] py-[12px] font-medium text-[#fff] bg-[#f48533] rounded-[10px] hover:bg-[#000] ease inline-block' to='/contact-us' onClick={windowScroll}>Buy Now</Link>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </section>
                {/* <section className="relative bg-[#F3F3F3]">
                    <div className='container'>
                        <div className='mb-[40px] max-w-[960px] text-center mx-auto'>
                            <h2>Broadband Plans in Chandigarh</h2>
                            <p>Experience the power of high-speed broadband with MyInternet in Chandigarh! Seamlessly stream high-definition videos without buffering, making your movie nights and binge-watching sessions more enjoyable</p>
                        </div>

                        <div className='grid xl:grid-cols-4 lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-[30px] mt-[50px]'>
                            {packageData.map((item, i) => (
                                <div key={i} className='border border-[#f48533] rounded-[10px]'>
                                    <div className='text-center p-[20px]'>
                                        <div className='mb-2 text-[#f48533]'>Upto</div>
                                        <h2 className='mb-[0]'>{item.speed}</h2>
                                    </div>
                                    <div className='p-[30px] bg-[#f48533] mb-[20px]' style={{ clipPath: 'polygon(0% 0%, 101% 0%, 100% 80%, 50% 100%, 0% 80%)' }}>
                                        <ul className="flex items-center justify-center gap-[40px]">
                                            {item.iconList.map((icons, index) => (
                                                <li key={index} className='flex items-center flex-col relative after:last:hidden after:content-["+"] after:absolute after:font-bold after:text-[25px] after:text-[white] after:ml-[12.5px] after:left-full after:top-0'>
                                                    <span className='w-[40px] h-[40px] bg-[#fff] flex items-center justify-center rounded-full mb-[10px]'>{icons.icon}</span>
                                                    <strong className='text-white'>{icons.iconTitle}</strong>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>

                                    <div className='px-[30px] mb-[20px] '>
                                        <div className='flex items-center justify-between border-b border-dashed border-[#f7dfcd] pb-2 mb-2 text-[#f48533]'>
                                            <div>12 Months</div>
                                            <h3 className='mb-0 text-[26px]'>{item.monthsPriceTwelve}/ <span className='text-[20px]'>mo*</span></h3>
                                        </div>
                                        <div className='flex items-center justify-between border-b border-dashed border-[#f7dfcd] pb-2 mb-2'>
                                            <div>6 Months</div>
                                            <h3 className='mb-0 text-[26px]'>{item.monthsPriceSix}/ <span className='text-[20px]'>mo*</span></h3>
                                        </div>
                                        <div className='flex items-center justify-between'>
                                            <div>3 Months</div>
                                            <h3 className='mb-0 text-[26px]'>{item.monthsPriceThree}/ <span className='text-[20px]'>mo*</span></h3>
                                        </div>
                                    </div>

                                    <div className='text-center px-[20px]'>
                                        <div>TV Channels</div>
                                        <ul>
                                            <li className='w-[50px] h-[50px] '><img src={ImageIcons.myinternetBanner} alt='' /></li>
                                        </ul>
                                    </div>

                                    <div className='text-center mb-[30px]'>
                                        <Link className='mx-auto px-[25px] py-[12px] font-medium text-[#fff] bg-[#f48533] rounded-[10px] hover:bg-[#000] ease inline-block' to='/contact-us'>Buy Now</Link>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </section> */}





                {(serviceDetails?.title && serviceDetails?.description && serviceDetails?.additionalInfo) &&
                    <section>
                        <div className='container'>
                            <h2>{serviceDetails?.title}</h2>
                            <p>{serviceDetails?.description}</p>
                            <div className='grid md:grid-cols-3 sm:grid-cols-2 gap-[20px] mt-[30px]'>
                                {serviceDetails?.additionalInfo?.map((item) => (
                                    <div className='lg:p-[40px] p-[25px] rounded-[10px] border border-t-[6px] border-[#e3e6f0] first:hover:border-[#da4b48] [&:nth-child(2)]:hover:border-[#21c87a] [&:nth-child(3)]:hover:border-[#796eff] [&:nth-child(4)]:hover:border-[#f38432] [&:nth-child(5)]:hover:border-[#000] duration-500'>
                                        <h3 className='lg:text-[26px] mb-[10px]'>{item?.title}</h3>
                                        <p className='mb-0'>{item?.description}</p>
                                    </div>
                                ))
                                }
                            </div>

                        </div>

                    </section>
                }
                {(serviceDetails?.whychoosewe && serviceDetails?.whychoosedescription && serviceDetails?.detail) &&
                    <section className='bg-[#f3f3f3]'>
                        <div className='container'>
                            <h2>{serviceDetails?.whychoosewe}</h2>
                            <p>{serviceDetails?.whychoosedescription}</p>
                            <div className='mt-[30px]'>
                                {serviceDetails?.detail?.map((item) => (
                                    <div className='py-[10px] mb-[30px] last:mb-0 pl-[20px] border-l-[4px] first:border-[#da4b48] [&:nth-child(2)]:border-[#21c87a] [&:nth-child(3)]:border-[#796eff] [&:nth-child(4)]:border-[#f38432] first:hover:border-[#da4b48] [&:nth-child(2)]:hover:text-[#21c87a] [&:nth-child(3)]:hover:text-[#796eff] [&:nth-child(4)]:hover:text-[#f38432] duration-500'>
                                        <h3 className='lg:text-[26px] mb-[10px]'>{item?.title}</h3>
                                        <p className='mb-0'>{item?.description}</p>
                                    </div>
                                ))
                                }
                            </div>
                        </div>
                    </section>
                }
                {data.some(item => item.name) && (
                    <section className='pb-0'>
                        <div className='container'>
                            <h2 className='md:mb-[30px]'>The areas covered by us include</h2>
                            <div className='mb-[10px] grid md:grid-cols-2 gap-x-[40px]'>
                                {data.filter(item => item.detail).map((item, i) => (
                                    <div>
                                        <h3>{item?.name}</h3>
                                        <p>{item.detail}</p>
                                    </div>
                                ))}
                            </div>
                            <h2 className='md:mb-[30px]'>Our other areas of service include</h2>
                            <ul className='grid lg:grid-cols-5 sm:grid-cols-3 grid-cols-2 gap-x-[10px]'>
                                {data.filter(item => !item.detail).map((item, i) => (
                                    <li className='mb-[10px] text-[16px] relative pl-[25px]'><IoCheckmarkDoneSharp className='text-[17px] inline-block text-[#f48533] absolute left-0 top-[5px]' />{item?.name}</li>
                                ))}
                            </ul>

                        </div>
                    </section>
                )}

                <section>
                    <div className='container'>
                        <div className='mb-[50px] text-center max-w-[850px] mx-auto'>
                            <h2>Enjoy High Speed Internet With  My Internet</h2>
                            <p>Experience the power of high-speed broadband with MyInternet in {city.cityname}! Seamlessly stream high-definition videos without buffering, making your movie nights and binge-watching sessions more enjoyable</p>
                        </div>

                        <div className='grid sm:grid-cols-3 grid-cols-2 md:gap-y-[80px] gap-y-[40px] text-center'>
                            <div className="relative px-[5px] after:absolute after:content-[''] after:w-[2px] after:h-[75%] after:right-0 after:top-[50%] after:-translate-y-2/4 after:bg-[#D1D1D1]">
                                <div className='mb-[20px]'>
                                    <img className='mx-auto h-[80px] max-lg:h-[70px] max-md:h-[60px] max-sm:h-[40px]' src={ImageIcons.unlimited} alt='Unlimited' />
                                </div>
                                <h6 className='mb-0 text-[20px] max-md:text-[18px] max-sm:text-[16px]'>Unlimited <br /> Downloads</h6>
                            </div>

                            <div className="relative px-[5px] after:absolute after:content-[''] after:w-[2px] after:h-[75%] after:right-0 after:top-[50%] after:-translate-y-2/4 after:bg-[#D1D1D1] max-sm:after:hidden">
                                <div className='mb-[20px]'>
                                    <img className='mx-auto h-[80px] max-lg:h-[70px] max-md:h-[60px] max-sm:h-[40px]' src={ImageIcons.support} alt='Support' />
                                </div>

                                <h6 className='mb-0 text-[20px] max-md:text-[18px] max-sm:text-[16px]'>24*7 Customer<br /> Support</h6>
                            </div>
                            <div className="relative px-[5px] sm:after:hidden after:absolute after:content-[''] after:w-[2px] after:h-[75%] after:right-0 after:top-[50%] after:-translate-y-2/4 after:bg-[#D1D1D1]">
                                <div className='mb-[20px]'>
                                    <img className='mx-auto h-[80px] max-lg:h-[70px] max-md:h-[60px] max-sm:h-[40px]' src={ImageIcons.speed} alt='1 Gbps speed' />
                                </div>

                                <h6 className='mb-0 text-[20px] max-md:text-[18px] max-sm:text-[16px]'>1 Gbps speed</h6>
                            </div>
                            <div className="relative px-[5px] after:absolute after:content-[''] after:w-[2px] after:h-[75%] after:right-0 after:top-[50%] after:-translate-y-2/4 after:bg-[#D1D1D1] max-sm:after:hidden">
                                <div className='mb-[20px]'>
                                    <img className='mx-auto h-[80px] max-lg:h-[70px] max-md:h-[60px] max-sm:h-[40px]' src={ImageIcons.device} alt='Seamless Device' />
                                </div>

                                <h6 className='mb-0 text-[20px] max-md:text-[18px] max-sm:text-[16px]'>Seamless Device<br /> Connectivity</h6>
                            </div>
                            <div className="relative px-[5px] after:absolute after:content-[''] after:w-[2px] after:h-[75%] after:right-0 after:top-[50%] after:-translate-y-2/4 after:bg-[#D1D1D1]">
                                <div className='mb-[20px]'>
                                    <img className='mx-auto h-[80px] max-lg:h-[70px] max-md:h-[60px] max-sm:h-[40px]' src={ImageIcons.insights} alt='Network Usage' />
                                </div>

                                <h6 className='mb-0 text-[20px] max-md:text-[18px] max-sm:text-[16px]'>Network Usage<br /> Insights</h6>
                            </div>
                            <div>
                                <div className='mb-[20px]'>
                                    <img className='mx-auto h-[80px] max-lg:h-[70px] max-md:h-[60px] max-sm:h-[40px]' src={ImageIcons.flexible} alt='Flexible plans' />
                                </div>

                                <h6 className='mb-0 text-[20px] max-md:text-[18px] max-sm:text-[16px]'>Flexible plans </h6>
                            </div>
                        </div>
                    </div>
                </section>
                {/* 
            <section className='bg-[#F3F3F3]'>
                <div className='container'>
                    <div className='grid lg:grid-cols-2 items-center gap-[50px]'>
                        <div>
                            <h2>Buy a new  Broadband connection in chandigarh</h2>
                            <p className='mb-[40px]'>Experience the power of high-speed broadband with MyInternet in Chandigarh! Seamlessly stream high-definition videos without buffering, making your movie nights and binge-watching sessions more enjoyable. </p>
                            <Button hrefLink='/contact-us' title='Contact Us' />
                        </div>

                        <div>
                            <img className='lg:ml-auto max-lg:m-auto' src={ImageIcons.myinternetBanner} alt='' />
                        </div>
                    </div>
                </div>
            </section> */}
                <section className='relative py-0'>
                    <div className='container'>
                        <div className='bg-[#633b25] text-white md:p-[50px] p-[30px] flex md:flex-nowrap flex-wrap justify-between items-center gap-[25px] rounded-[10px] bg-cover bg-no-repeat' style={{ backgroundImage: `url(${ImageIcons.faqBg})` }}>
                            <div className='max-lg:w-[400px] max-md:w-full'>
                                <h2 className='font-medium mb-[10px]'>Get in touch</h2>
                                <p className='lg:text-[25px] md:text-[20px] text-[18px] mb-0'>Head over to our contact page to connect with us today!</p>
                            </div>

                            <Button hrefLink='/contact-us' title="Contact Us" />
                        </div>
                    </div>
                </section>

                <section>
                    <div className="container !max-w-[1100px]">
                        <div className="mx-auto bg-white   rounded-lg">
                            <h2 className='mb-[40px] text-center'>Frequently Asked Questions</h2>
                            {faq?.map((faq, index) => (
                                <AccordionItem
                                    key={index}
                                    active={activeIndex === index}
                                    handleToggle={() => handleToggle(index)}
                                    faq={faq}
                                />
                            ))}

                            <div className='mt-[40px] text-center'>
                                <Button hrefLink='/faqs' title='More Faqs' />
                            </div>
                        </div>
                    </div>
                </section>


            </Layout >
        </>
    )
}

const AccordionItem = ({ handleToggle, active, faq }) => {
    const contentRef = useRef(null);
    const [height, setHeight] = useState('0px');

    useEffect(() => {
        if (contentRef.current) {
            setHeight(active ? `${contentRef.current.scrollHeight}px` : '0px');
        }
    }, [active]);

    return (
        <div className="mb-[20px] shadow-[0px_3px_15px_0px_rgba(0,0,0,0.1)] overflow-hidden rounded-[10px]">
            <div
                className={`p-[20px] flex justify-between items-center cursor-pointer ${active ? 'bg-[#f48533] text-white' : ''}`}
                onClick={handleToggle}
            >
                <h5 className="mb-0 lg:text-[20px] text-[18px] ">{faq?.question}</h5>
                <span>{active ? <FaMinus /> : <FaPlus />}</span>
            </div>
            <div
                ref={contentRef}
                className={`overflow-hidden transition-all duration-300`}
                style={{ maxHeight: height }}
            >
                <div className="p-[20px] ">
                    <p className='mb-0'>{faq?.answer}</p>

                    {faq.answerList && <ul className='mt-[15px]'>
                        {faq.answerList.map((faq, i) => (
                            <li className='mb-[8px] text-[16px] relative pl-[25px]'><IoCheckmarkDoneSharp className='text-[17px] inline-block text-[#f48533] absolute left-0 top-[5px]' /> {faq}</li>
                        ))}
                    </ul>
                    }
                </div>
            </div>
        </div>
    );
};



export default CitiesDetail