import React from 'react';
import { Link } from 'react-router-dom';
import { windowScroll } from '../utils/windowScroll';

const Button = ({ title = "", hrefLink = "" }) => {
    return (
        <Link to={hrefLink} onClick={windowScroll} className="px-[50px] text-[18px] py-[16px] font-medium text-[#fff] bg-[#f48533] rounded-[10px] hover:bg-[#000] ease inline-block">
            {title}
        </Link>
    )
}

export default Button;